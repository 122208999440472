/**
 * Removes the protocol prefix and codejet domain suffix from a URL.
 * @param url - The URL to be cleaned.
 * @returns The cleaned URL without the protocol prefix and domain suffix.
 */
export const cleanUrl = (url: string) => {
  // Remove the http:// or https:// prefix
  let cleanedUrl = url.replace(/^https?:\/\//, "");

  // Remove the .dev.codejet.site or .codejet.site suffix
  cleanedUrl = cleanedUrl.replace(/(\.dev\.codejet\.site|\.codejet\.site)$/, "");

  return cleanedUrl;
};
