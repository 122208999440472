import { useEffect, useState } from "react";

import { ButtonNew } from "../../../atoms/ButtonNew";

import { AlertTriangle } from "../../../../../assets/svg/alert-triangle";

import { UserService } from "../../../../services/UserService/UserService";
import { PaymentsService } from "../../../../services/Payments/Payments";

import { useAsyncAction } from "../../../../utils/useAsyncAction/useAsyncAction";

import { type TInfoBar } from "./InfoBar.types";

import "./InfoBar.css";

const userService = UserService.getInstance();

const messages = {
  "free-plan": {
    title: "Need more projects?",
    subtitle:
      "Upgrade to the Professional Plan for enhanced capabilities to create and publish up to 5 projects on your custom domains.",
  },
  "professional-plan-domains": {
    title: "Professional Plan exceeded",
    subtitle:
      "Limit of 5 custom domains reached. Switch to the Team Plan for unlimited access or contact us for more details.",
  },
  "professional-plan-projects": {
    title: "Professional Plan exceeded",
    subtitle:
      "Limit of 100 projects reached. Switch to the Team Plan for unlimited access or contact us for more details.",
  },
};

export const InfoBar: TInfoBar = ({ icon = <AlertTriangle />, type, onClick }) => {
  const [_, setState] = useState(userService.state);

  const { isLoading, handleAsyncAction } = useAsyncAction();
  const priceID = import.meta.env.VITE_SUBSCRIPTION_PRICE_ID;
  const isActiveFlag = userService.isActiveSubscriptionFlag();

  const handleOnClick = () => {
    if (onClick) return onClick();
    if (isActiveFlag) return handleAsyncAction(async () => await PaymentsService.createPortalSession());
    return handleAsyncAction(async () => await PaymentsService.createCheckoutSession(priceID, "subscription"));
  };

  useEffect(() => {
    userService.subscribe(setState);
    return () => userService.unsubscribe(setState);
  }, []);

  if (!type) {
    return null;
  }
  return (
    <div className="infobar">
      {!icon ? null : <div className="infobar__icon">{icon}</div>}
      <div className="infobar__text">
        <span className="infobar__title">{messages[type].title}</span>
        <span className="infobar__subtitle">{messages[type].subtitle}</span>
      </div>
      <ButtonNew className="infobar__button" onClick={handleOnClick} loading={isLoading} orange>
        Upgrade Your Plan
      </ButtonNew>
    </div>
  );
};
