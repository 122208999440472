import { useState, useEffect, useCallback, useRef } from "react";
import { modalMachineInstance } from "../../index.ts";
import { projectsService } from "../../../../../../../machines/projects/projects.instance.ts";
import { editorInstance } from "../../../editor/index.ts";
import { UserService } from "../../../../../../../services/UserService/UserService.ts";
import { Close } from "../../../../../../../assets/svg/Close.tsx";
import axios from "axios";
import debounce from "lodash/debounce";

import "./Domains.css";
import { Button } from "../../../../../../AlphaO/atoms/Button/Button.tsx";
import { Subdomain, CustomDomain, DomainsModalFooter } from "./DomainComponents.tsx";
import { ComingSoon } from "../../../../../../atoms/CoomingSoon/ComingSoon.tsx";

const userService = UserService.getInstance();

export const DomainsModal = () => {
  const [_, updateProjectsState] = useState(projectsService.state);
  const [__, updateUserState] = useState(userService.state);
  const [editorState, setEditorState] = useState(editorInstance.state.value);
  const [isDefaultEditPanelVisible, setIsDefaultEditPanelVisible] = useState(false);
  const [subdomain, setSubdomain] = useState("");
  const [validationError, setValidationError] = useState<string | null>(null);
  const [availabilityError, setAvailabilityError] = useState<string | null>(null);
  const activeRequest = useRef<AbortController | null>(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  useEffect(() => {
    projectsService.subscribe(updateProjectsState);
    userService.subscribe(updateUserState);

    editorInstance.onTransition((state) => {
      setEditorState(state.value);
    });

    return () => {
      userService.unsubscribe(updateUserState);
      projectsService.unsubscribe(updateProjectsState);
    };
  }, []);

  const maxCustomDomainsCount = 5;
  const activeProject = projectsService.getActiveProject();
  if (!activeProject) return null;

  const customDomainsCount = projectsService.getProjectsWithPublishedCustomDomain().length;
  const isActiveSubscription = !userService.canPurchaseSubscription();
  const isSwitchDisabled =
    activeProject?.customDomain?.address === "" ||
    !isActiveSubscription ||
    (customDomainsCount >= maxCustomDomainsCount && !activeProject?.customDomain.published);
  const showContactSales = customDomainsCount >= maxCustomDomainsCount && !activeProject?.customDomain.published;
  const canEditCustomDomain =
    (customDomainsCount < maxCustomDomainsCount || activeProject?.customDomain.published) && isActiveSubscription;
  const canPublishCustomDomain =
    isActiveSubscription && activeProject?.customDomain?.address !== "" && customDomainsCount < maxCustomDomainsCount;

  const handlePublishSubdomain = () => {
    if (editorState !== "READY") return;

    if (activeProject?.subdomain?.published) {
      editorInstance.send("DOMAIN_TO_UNPUBLISH", { domainToUnpublish: "subdomain" });
      modalMachineInstance.send("CHANGE_VIEW", { viewId: "unpublishDomain" });
    } else {
      editorInstance.send("PUBLISH_PROJECT", { customDomain: false });
    }
  };

  const handlePublishCustomDomain = () => {
    if (!isActiveSubscription || editorState !== "READY") return;

    if (activeProject?.customDomain?.published) {
      editorInstance.send("DOMAIN_TO_UNPUBLISH", { domainToUnpublish: "customDomain" });
      modalMachineInstance.send("CHANGE_VIEW", { viewId: "unpublishDomain" });
    } else {
      if (canPublishCustomDomain) {
        editorInstance.send("PUBLISH_PROJECT", { customDomain: true });
      }
    }
  };

  const handlePublishChanges = () => {
    if (editorState === "READY") {
      editorInstance.send("PUBLISH_UPDATES");
    }
  };

  const checkSubdomainAvailability = async (value: string) => {
    if (activeRequest.current) {
      activeRequest.current.abort();
    }
    const controller = new AbortController();
    activeRequest.current = controller;

    try {
      const response = await projectsService.checkSubdomainAvailability(value, controller.signal);

      if (response.status === 200) {
        setAvailabilityError(null);
        setIsButtonDisabled(false);
      }
    } catch (error: any) {
      if (axios.isCancel(error)) {
        // console.error("Request canceled", error.message);
      } else if (error.response && error.response.status === 409) {
        setAvailabilityError("Subdomain is not available");
      } else {
        console.error("An error occurred:", error);
      }
    } finally {
      activeRequest.current = null;
    }
  };

  const checkSubdomainAvailabilityDebounced = useCallback(debounce(checkSubdomainAvailability, 250), []);

  const isValidSubdomain = (subdomain: string) => {
    const subdomainRegex = /^[a-zA-Z0-9]([a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?$/;
    return subdomainRegex.test(subdomain);
  };

  const handleChangeSubdomain = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSubdomain(value);
    const activeSubdomain = activeProject?.subdomain?.address;

    if (value === activeSubdomain) {
      setIsButtonDisabled(true);
    }

    if (isValidSubdomain(value)) {
      setValidationError(null);
      await checkSubdomainAvailabilityDebounced(value);
    } else {
      setValidationError("Subdomain is not valid");
    }

    if (value.length === 0) {
      setValidationError(null);
      setAvailabilityError(null);
      setValidationError(null);
      setAvailabilityError(null);
      setIsButtonDisabled(true);
    }
  };

  const handleSaveSubdomain = async () => {
    if (validationError === null && availabilityError === null) {
      await checkSubdomainAvailability(subdomain);
      if (availabilityError !== null) {
        return;
      }
      projectsService
        .updateProjectData(activeProject?.projectId || "", {
          subdomain: subdomain,
        })
        .then(() => {
          setSubdomain("");
          setValidationError(null);
          setAvailabilityError(null);
          setIsDefaultEditPanelVisible(false);
          setIsButtonDisabled(true);
        })
        .catch((error) => {
          console.error("An error occurred:", error);
        });
    }
  };

  return (
    <div className="domains-modal">
      <header className="domains-modal__header">
        <h2 className="domains-modal__heading">Publish project</h2>
        <p className="domains-modal__description">Deploy your project on Codejet or custom domain</p>
        <Button
          className="domains-modal__close"
          onClick={() => modalMachineInstance.send("CLOSE")}
          aria-label="close modal"
        >
          <Close />
        </Button>
      </header>
      <div className="domains-modal__separator"></div>
      <div className="domains-modal__content">
        <Subdomain
          activeProject={activeProject}
          handlePublishSubdomain={handlePublishSubdomain}
          isDefaultEditPanelVisible={isDefaultEditPanelVisible}
          setIsDefaultEditPanelVisible={setIsDefaultEditPanelVisible}
          subdomain={subdomain}
          handleChangeSubdomain={handleChangeSubdomain}
          subdomainError={validationError || availabilityError}
          handleSaveSubdomain={handleSaveSubdomain}
          disabledSave={
            isButtonDisabled || subdomain.length === 0 || availabilityError !== null || validationError !== null
          }
        />
        <CustomDomain
          activeProject={activeProject}
          isSwitchDisabled={isSwitchDisabled}
          handlePublishCustomDomain={handlePublishCustomDomain}
          showContactSales={showContactSales}
          isActiveSubscription={isActiveSubscription}
          canEditCustomDomain={canEditCustomDomain}
        />
      </div>
      <div className="domains-modal__separator"></div>
      <DomainsModalFooter
        activeProject={activeProject}
        editorState={editorState}
        handlePublishChanges={handlePublishChanges}
      />
    </div>
  );
};
