import { type CSSProperties } from "react";

type TDashboard = {
  className?: string;
  style?: CSSProperties;
};

export const Dashboard = ({ className, style }: TDashboard) => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M11.666 17.5013V12.668C11.666 12.3918 11.8898 12.168 12.166 12.168H16.9993C17.2755 12.168 17.4993 12.3918 17.4993 12.668V17.5013C17.4993 17.7775 17.2755 18.0013 16.9993 18.0013H12.166C11.8898 18.0013 11.666 17.7775 11.666 17.5013Z"
      fill="#FF4900"
    />
    <path
      d="M2.5 17.5013V12.668C2.5 12.3918 2.72386 12.168 3 12.168H7.83333C8.10948 12.168 8.33333 12.3918 8.33333 12.668V17.5013C8.33333 17.7775 8.10948 18.0013 7.83333 18.0013H3C2.72386 18.0013 2.5 17.7775 2.5 17.5013Z"
      fill="#71717A"
    />
    <path
      d="M11.666 8.33333V3.5C11.666 3.22386 11.8898 3 12.166 3H16.9993C17.2755 3 17.4993 3.22386 17.4993 3.5V8.33333C17.4993 8.60948 17.2755 8.83333 16.9993 8.83333H12.166C11.8898 8.83333 11.666 8.60948 11.666 8.33333Z"
      fill="#71717A"
    />
    <path
      d="M2.5 8.33333V3.5C2.5 3.22386 2.72386 3 3 3H7.83333C8.10948 3 8.33333 3.22386 8.33333 3.5V8.33333C8.33333 8.60948 8.10948 8.83333 7.83333 8.83333H3C2.72386 8.83333 2.5 8.60948 2.5 8.33333Z"
      fill="#71717A"
    />
  </svg>
);
