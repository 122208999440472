import { useState } from "react";
import vector from "../../../../assets/png/Vector.png";
import "./Accordion.css";
export const Accordion = ({
  label,
  children,
  className = "",
  position = "right",
}: {
  label: string;
  children: React.ReactNode;
  className?: string;
  position?: "right" | "left";
}) => {
  const [isOpened, setIsOpened] = useState(false);
  const handleClick = () => {
    setIsOpened((prev) => !prev);
  };
  return (
    <div className="accordion">
      <h2
        className={`accordion__heading${className ? ` ${className}` : ""}${
          position === "left" ? " accordion__heading--left" : ""
        }${isOpened ? " active" : ""}`}
        onClick={handleClick}
      >
        {position === "left" && <img src={vector} alt="" />}
        {label}
        {position === "right" && <img src={vector} alt="" />}
      </h2>
      <div className={`accordion__content${isOpened ? " active" : ""}`}>{children}</div>
    </div>
  );
};
