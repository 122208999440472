import { createMachine } from "xstate";
import { UserService } from "../../services/UserService/UserService";
import { getWindow } from "../../utils/getWindow/getWindow";
import { Figma } from "../../services/Figma/Figma";
import { routerInstance } from "../router/router.instance";
import { dashboardInstance } from "../dashboard/dashboard.instance";

const authMachine = createMachine(
  {
    /** @xstate-layout N4IgpgJg5mDOIC5QEECuAXAFgOgMoBVkAlfAYggHsA7MbASyoDcKBrWtLPQkhB5gYwCG6OtQDaABgC6kqYlAAHCrDojq8kAA9EAFgCsE7AE4AjAA4ATAHYjeozokOzAGhABPXQDYL2T56vmEnpmZjZWAMx6AL5Rrhw4BMRkYABOKRQp2AoANsIAZhkAttjxXEm8TBRCalSyshpKKjUa2ggm4TrYlp7h4Y5WBvq2rh5tOp0WeiZ6ejqeZr3hMXEYCdxkAPLIAKr4ABL1SCCNqqJULboGxubWtvaOEi7uiBY6PndzOvaeJo+eyyBSokSKRNLB0MJaII8uhUgAKX4SCQASlIQPWh0UylO6iOrX0hlMlhsdgcThGiDMJmwXyMdKs1gsJh0zLMANKW12e3I1FofFY7FW2E5+wqAmEZzq0ga2OaeMQiOpCzMEnCvz0nkcaopCAsRnC2CsqpMJr6VnNIR07KFIu5qXSmRy+SKJRtO1F-OqkukmOOsrOFzaEhMRmwEh64VsfhVryMOvanhp+v0HSsZiM-mm1s47r2AFEAHL4ACSAGFkPg8wARUgAGQ2AHENrtfSc5aBWszPJ0ehETAMLBYOnorDrwiFsOEet4019NYPszhtgXc4WS+XKzXKDR6JU2K7OMvV0WyxXq2KqhLxD7pUc2wH5W0rDorMYCe1rN4jCEddY9NgLDMcYvnCZ8R1AxdsCPLk11PTdSHtDIslydAChSYpSmg-ZYI3c9PSvWobzkO9-VxDsFWfMxfBMeZ9VCE17DjZ42ksSc1V6cxAlsNkASoCgIDgDR4hlJoH3IhAAFoTB1CTEyRJETRHCJIyAkxIOBfARJxc5HxfX83gAxx-EHFSJDTaJYkBN0uS09stEQeZ-z0T9SWsNNpn0nwLCMjUTEHdoMwsSDj3XM8q1ssT7LaG4aRfMkqWDaTmPHV9NVCMx-CMCQ9XmSCsPzE9cPCkjRLIqKaO7ScFmfR4gM1b8xwiADQI1Tx9XVLMYiiIA */
    tsTypes: {} as import("./auth.machine.typegen").Typegen0,
    predictableActionArguments: true,
    id: "Auth",
    initial: "START",
    states: {
      START: {
        invoke: {
          src: "isUserAuthenticated",
          onDone: {
            target: "AUTHENTICATED",
          },
          onError: {
            actions: () => console.log("START: UNAUTHENTICATED"),
          },
        },
        on: {
          OAUTH: {
            target: "OAUTH",
            actions: () => console.log("START: OAUTH"),
          },
        },
        after: {
          1000: "UNAUTHENTICATED",
        },
      },

      OAUTH: {
        invoke: {
          src: "verifyToken",
          onDone: {
            target: "AUTHENTICATED",
            actions: () => console.log("OAUTH: AUTHENTICATED"),
          },
          onError: {
            actions: () => console.log("OAUTH: UNAUTHENTICATED"),
            target: "UNAUTHENTICATED",
          },
        },
      },

      AUTHENTICATED: {
        entry: () => {
          dashboardInstance.send("AUTHENTICATED");
        },
        on: {
          LOGOUT: {
            target: "UNAUTHENTICATED",
            actions: "logout",
          },
          SESSION_EXPIRED: {
            target: "SESSION_EXPIRED",
          },
        },
      },

      UNAUTHENTICATED: {
        // entry: () => routerInstance.send("LOGIN"),
        invoke: {
          src: "isFigmaPluginFlow",
          onDone: {
            actions: () => routerInstance.send("FIGMA_PLUGIN"),
          },
          onError: {
            actions: () => routerInstance.send("LOGIN"),
          },
        },
      },

      SESSION_EXPIRED: {
        entry: ["triggerDashboardLogout", "logout"],
        always: {
          target: "UNAUTHENTICATED",
        },
      },
    },
  },
  {
    services: {
      isFigmaPluginFlow: async () => {
        const figmaFlow = localStorage.getItem("figmaFlow");
        const isFigmaFlow = figmaFlow === "true";

        if (isFigmaFlow) return Promise.resolve();
        return Promise.reject();
      },
      verifyToken: async () => {
        const { searchParams } = new URL(getWindow().location.href);
        const code = searchParams.get("code");

        if (!code) return Promise.reject();

        try {
          await Figma.getAccessToken(code);
          //we have our cookie with jwt now
          // userService.saveStorage(data);
          // return Promise.resolve();
        } catch (error) {
          console.error("Figma.getAccessToken");
          //when no access token
        }

        try {
          const userService = UserService.getInstance();
          const isValid = await userService.isTokenValid();

          //We check if JWT is still valid
          // JWT should be in httpOnly cookie
          // Cookie is set by server and has name JWT
          if (isValid) {
            return Promise.resolve();
          } else {
            return Promise.reject();
          }
        } catch (error) {
          return Promise.reject();
        }
      },
      //TESTED
      isUserAuthenticated: async () => {
        return UserService.getInstance().check();
      },
    },
    actions: {
      //TESTED
      logout: () => {
        UserService.getInstance().logout();
      },
      triggerDashboardLogout: () => {
        dashboardInstance.send("LOGOUT");
      },
    },
    guards: {},
  }
);

export { authMachine };
