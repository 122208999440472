/**
 * Copies the specified text to the clipboard.
 *
 * @param text - The text to be copied to the clipboard.
 */
export const copyToClipboard = (text: string) => {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      console.log("Copied to clipboard");
    })
    .catch((error) => {
      console.error("Failed to copy: ", error);
    });
};
