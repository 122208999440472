import { createMachine } from "xstate";
import { Editor } from "grapesjs";
import { EditorService } from "../editor/editor.service.ts";
import { projectsService } from "../../../../../machines/projects/projects.instance.ts";
import { pagesService } from "./pages.service.ts";

export type EditorContext = { editor?: Editor; service: EditorService };
export const pagesMachine = (service: EditorService) =>
  createMachine<EditorContext>(
    {
      /** @xstate-layout N4IgpgJg5mDOIC5QGEBKBRAggFXQfQFUBldVPAMQHlUBZAOiO01WwGIBtABgF1FQAHAPawAlgBcRggHZ8QAD0QBGACzK6ADgBM6xYoDsmzgDYjATlPqjAGhABPJQGZFGsw6169p5Z3UBWAL7+NmhYuIQkZFS0dOTo2MgAEngAigTojACSlAByRKwQ0mB0IlIAboIA1kUhOPjEpBTU9LHxSanp2Fm5CCXlAMYAhhLSXNyjskKiwzJI8ojKRmqmDnrKpoqceoq+yirKNvYIXhqm2pp66uoOvtu+eoHBGLXhDVHNcYkpaZk5eWAATv9BP86PwADZDABmwIAtnQamF6pEmjEPm1vp1fj0yoJBtNRuNZpNxJIZqAFAgFksVmsNlsdnsDogjA5OHRfL5zL5Wb4jOo9EYHiAEXUIo1ou0frlWERMAA1fCYXIAdVIhIEwhJ0lkFL8ahZ6mMmx2nB8miZCF8miMdE43lWpiM1sUmgcgqCwqeiLFbzoksx0uy6AAGtgvh0uuqQMTpjrEHq6KZjJp9G5tEYLhbdr5E9yXeY6ZZVkKRS9kRKMV08gAFDByrLEcNSqMx0lxhAJpNO1NaIvqC36NTKPOGLzWvnKEte0WvFGyhV4JVEVWofKFYo4qrw6dl8X0eeKlWkbH9Iakgk8Caa2OzXXHZTqZROF1GO7D812eZXdlu3ycTQ7H+r6KFOoQzuW9AZEQFAZNkmAADJNgGHCXkS15trejj6HQygplopp+CY-afpSdx0BciwsnonCKI+tEBB6pZInudBQTBcGIf6XQcIovBoVMGHklheg4Xhhg+LyfJZro5GWAsnB3Ga5igc8zG+kGoZIdxBRSEUvSVNUO5qSiGlhlxOQnriZ4jDwLbodqmEIIoTimHQ1ocuoFhJvoqwWucDiJgKkkrB4r6aCp3qztEtboPWlCNuZ2RrrpG7lFuTE+iiMVxQllYWfpeLnrZqEagJDlCR2RjODcHlbGczkWjoOHmOYbgCk6VoMY8YG7r6MXVswh7LqQeQ6Xpm6GT1xnRRgA0YIuR6oEQlmFTZYwldG9lknMTmrJo7JOu+2zeA4pxZmRnBugs5zaOYPgReBLFLiu0FPAAIgAmjK6DZG9C3DUtdlldtFIpqdOF8nop18qYkm+OdObcpRmxXNolhdZ6U2ZdEJC-f9L3JeNaWTap2P7j9f3PSNK3WVIF58aVWog4gAHg3cOjXGmdpeFmhi2pynl7KoZheA9vVzhT+MjawAJAiC4JQrC25Y1F5N41TS00-ixUM5twPtqzrns853L4Ws+wkTcAU0ay9EbFbIFClIggQHAsgZarV7645AC01gkX7tqmqavh+JsejclDGMexBDBMCwXtM+2uEDqyLjLHyOyaK6OwOGL03vK0Wm-InN4Va6rmnLRHiGJcnkDpsdBul4l1Vd4hrWvnZN+nluSl4JO0mCJVePrDEftwOig2lVY5uMYnmbF3qsMPKQ0rv35U7YWOHcsapzjwKWarLmTg3Pol0CtsS+x2x5CwQhxfZBvzNORsbKGgYKb6NnSbEYcuw2mHM+S+yxtjqGvixUyj9n7tm2BsOgh1TDUVoqcMKflLh0F0EgiO5gPDtwgX1OsDZoKJRgY5beQC97nB2IfEisMEFPmtEmM+LpuQEKyrNQaUslpkIqnAtkiDkGeXch+f+Dh9pI25DdWG11o5GW7hrV6WBPq8K3rDfaCx+TnFOJyXQWZxE-lfOIgwMi27sJxpLRRqjQbiJzNsQ6eY3QrH9mIiRV0HBPmwRcSw5jmiwSggkdAb1rEsyhs4R0-JqKcHWBmeGJFVBsiRqoZQeC-DUTkSrWOpBUDUBCQgfy4TIZRJiRHC0NxXLWlZNE9GtEHB50CP4IAA */
      predictableActionArguments: true,
      id: "ALPHA_O",
      initial: "START",
      context: {
        service,
      },
      states: {
        START: {
          on: {
            LOAD_PAGES: "LOAD_PAGES",
          },
        },

        LOAD_PAGES: {
          entry: ["updatePages", "notify"],
          on: {
            SELECT_PAGE: "SELECT_PAGE",
            ADD_PAGE: "ADD_PAGE",
            DELETE_PAGE: "DELETE_PAGE",
          },
        },

        SELECT_PAGE: {
          invoke: {
            src: "selectPage",
            onDone: {
              target: "READY",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        ADD_PAGE: {
          invoke: {
            src: "addPage",
            onDone: {
              target: "READY",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        DELETE_PAGE: {
          invoke: {
            src: "deletePage",
            onDone: {
              target: "READY",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        READY: {
          entry: ["updatePages", "notify"],
          on: {
            SELECT_PAGE: "SELECT_PAGE",
            ADD_PAGE: "ADD_PAGE",
            DELETE_PAGE: "DELETE_PAGE",
          },
        },

        FINISH: {
          type: "final",
        },

        ERROR: {},
        //
      },
    },
    {
      actions: {
        notify: () => {
          pagesService.notifySubscribers();
        },
        updatePages: ({ service }) => {
          //when editor will change number of pages get pages and update pagesService
          const editor = service.getEditor();
          if (editor) {
            const pages = service.getEditor()?.Pages.getAll();
            if (pages) {
              pagesService.state = pages;
            }
          }
        },
        //appendActions
      },
      services: {
        //appendServices
        selectPage: async ({ service }, event) => {
          const editor = service.getEditor();
          const { id } = event;
          editor?.Pages.select(id);
          return Promise.resolve();
        },

        addPage: async ({ service }, event) => {
          const { pageId, pageName } = event;
          const editor = service.getEditor();
          editor?.Pages.add({ id: pageId, name: pageName || "Default page" });
          return Promise.resolve();
        },

        deletePage: async ({ service }, event) => {
          const { id } = event;
          const editor = service.getEditor();
          editor?.Pages.remove(id);
          return Promise.resolve();
        },
      },

      guards: {
        //appendGuards
      },
    }
  );
