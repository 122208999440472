import { type CSSProperties } from "react";

type TInsert = {
  className?: string;
  style?: CSSProperties;
};

export const Insert = ({ className, style }: TInsert) => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M10 4.66699V16.3337"
      stroke="#FF4900"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.16602 10.5H15.8327"
      stroke="#FF4900"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
