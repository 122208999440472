import { dashboardInstance } from "../../../../machines/dashboard/dashboard.instance";

import { ButtonNew } from "../../../atoms/ButtonNew";

import { ChevronMini } from "../../../../../assets/svg/chevron-mini";

import { TProjectSettingsBreadcrumbs } from "./ProjectSettingsBreadcrumbs.types";

import "./ProjectSettingsBreadcrumbs.css";

export const ProjectSettingsBreadcrumbs: TProjectSettingsBreadcrumbs = ({ title }) => {
  return (
    <ul className="project-settings__breadcrumbs">
      <ButtonNew
        type="text"
        className="project-settings__breadcrumbs--btn"
        onClick={() => dashboardInstance.send("PROJECTS")}
      >
        My projects
      </ButtonNew>
      <ChevronMini />
      <ButtonNew orange type="text">
        {title}
      </ButtonNew>
    </ul>
  );
};
