import { useEffect, useState } from "react";

import { Button } from "../../atoms/Button";
import { Dropdown } from "../../atoms/Dropdown";
import { ButtonNew } from "../../../atoms/ButtonNew";
import { ImportModal } from "../../molecules/ImportModal";

import { Plus } from "../../../../../assets/svg/plus";
import { Logotype } from "../../../../../assets/svg/logotype";

import { UserService } from "../../../../services/UserService/UserService";
import { dashboardInstance } from "../../../../machines/dashboard/dashboard.instance";
import { ImportModal as ImportModalService } from "../../molecules/ImportModal/ImportModal.service";

import type { TNavigation } from "./Navigation.types";

import "./Navigation.css";

const userService = UserService.getInstance();
const importModal = ImportModalService.getInstance();

export const Navigation: TNavigation = () => {
  const [_, setState] = useState(userService.state);
  const openModal = () => importModal.openModal();
  const [location, setLocation] = useState(window.location.pathname);

  useEffect(() => {
    userService.subscribe(setState);
    setLocation(window.location.pathname);
    return () => userService.unsubscribe(setState);
  }, []);

  const user = {
    name: userService.data?.NAME,
    email: userService.data?.EMAIL,
    avatar: userService.data?.IMG_URL,
  };
  return (
    <nav className="navigation container">
      <a className="naviation__btn-logo" href="/" aria-label="Home">
        <Logotype />
      </a>
      <div className="navigation__btns">
        <Button active={location === "/projects"} onClick={() => dashboardInstance.send("PROJECTS")}>
          My projects
        </Button>
        <Button active={location === "/community"} onClick={() => dashboardInstance.send("COMMUNITY")}>
          Community
        </Button>
      </div>
      <ButtonNew className="navigation__btns--import-modal" orange iconStart={<Plus />} onClick={openModal}>
        Import from Figma
      </ButtonNew>
      <Dropdown label={user.name} avatarSrc={user.avatar} />
      <ImportModal />
    </nav>
  );
};
