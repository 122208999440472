export function convertToEmbeddedLink(url: string) {
  const urlObj = new URL(url);
  const videoId = urlObj.searchParams.get("v");
  const playlistId = urlObj.searchParams.get("list");

  if (!videoId) {
    throw new Error("Invalid YouTube URL: Video ID is missing.");
  }

  let embeddedUrl = `https://www.youtube.com/embed/${videoId}`;
  if (playlistId) {
    embeddedUrl += `?list=${playlistId}`;
  }

  return embeddedUrl;
}
