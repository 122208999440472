import { type CSSProperties } from "react";

type TUserScan = {
  className?: string;
  style?: CSSProperties;
};

export const UserScan = ({ className, style }: TUserScan) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <g clipPath="url(#clip0_12432_2022)">
      <path
        d="M5.83398 3.33301H3.33398V5.83301M14.1673 3.33301H16.6673V5.83301M5.83398 16.6663H3.33398V14.1663M14.1673 16.6663H16.6673V14.1663"
        stroke="#727272"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.44194 12.4419C8.68602 12.1979 8.68602 11.8021 8.44194 11.5581C8.19786 11.314 7.80214 11.314 7.55806 11.5581L8.44194 12.4419ZM3.94194 16.9419L8.44194 12.4419L7.55806 11.5581L3.05806 16.0581L3.94194 16.9419Z"
        fill="#727272"
      />
      <path
        d="M12.4419 11.5581C12.1979 11.314 11.8021 11.314 11.5581 11.5581C11.314 11.8021 11.314 12.1979 11.5581 12.4419L12.4419 11.5581ZM16.9419 16.0581L12.4419 11.5581L11.5581 12.4419L16.0581 16.9419L16.9419 16.0581Z"
        fill="#727272"
      />
      <path
        d="M7.55806 8.44194C7.80214 8.68602 8.19786 8.68602 8.44194 8.44194C8.68602 8.19786 8.68602 7.80214 8.44194 7.55806L7.55806 8.44194ZM3.05806 3.94194L7.55806 8.44194L8.44194 7.55806L3.94194 3.05806L3.05806 3.94194Z"
        fill="#727272"
      />
      <path
        d="M11.5581 7.55806C11.314 7.80214 11.314 8.19786 11.5581 8.44194C11.8021 8.68602 12.1979 8.68602 12.4419 8.44194L11.5581 7.55806ZM16.0581 3.05806L11.5581 7.55806L12.4419 8.44194L16.9419 3.94194L16.0581 3.05806ZM7.24134 28.5765L28.7413 19.5765L28.2587 18.4235L6.75866 27.4235L7.24134 28.5765Z"
        fill="#727272"
      />
    </g>
    <defs>
      <clipPath id="clip0_12432_2022">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
