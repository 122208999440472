import { LoadingDots } from "../../../atoms/LoadingDots";

import type { TButton } from "./Button.types";

import "./Button.css";

export const Button: TButton = ({
  className,
  iconStart,
  iconEnd,
  onClick,
  children,
  disabled,
  loading,
  type = "primary",
}) => {
  const baseClass = "button-alpha-o";
  const classes = [
    baseClass,
    type === "primary" && `${baseClass}--primary`,
    type === "secondary" && `${baseClass}--secondary`,
    type === "ternary" && `${baseClass}--ternary`,
    type === "icon" && `${baseClass}--icon`,
    className,
  ]
    .filter(Boolean)
    .join(" ");
  return (
    <button className={classes} onClick={onClick} disabled={disabled} type="button">
      {iconStart && <span className={`${baseClass}__icon-start`}>{iconStart}</span>}
      {children && <span className={`${baseClass}__typography`}>{children}</span>}
      {loading && <LoadingDots />}
      {iconEnd && <span className={`${baseClass}__icon-end`}>{iconEnd}</span>}
    </button>
  );
};
