import { useState } from "react";

import { ChevronDownLight } from "../../../../assets/svg/chevron-down-light";
import { ChevronRightLight } from "../../../../assets/svg/chevron-right-light";
import { FileOpen } from "../../../../assets/svg/file-open";
import { FileClose } from "../../../../assets/svg/file-close";
import { CatalogOpen } from "../../../../assets/svg/catalog-open";
import { CatalogClose } from "../../../../assets/svg/catalog-close";

import type { TTreeView, TreeData } from "./TreeView.types";

import "./TreeView.css";

export const TreeView: TTreeView = ({ data, isChevron = true, className, nodeName, onNodeClick }) => {
  const [expandedNodes, setExpandedNodes] = useState<{ [key: string]: boolean }>({});

  const toggleNode = (key: string) => {
    setExpandedNodes((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  const handleNodeClick = (key: string, value: string | TreeData) => {
    if (typeof value !== "object") {
      onNodeClick && onNodeClick(key, value);
    } else {
      toggleNode(key);
    }
  };

  return (
    <ul
      role={nodeName ? "treeitem" : "tree"}
      aria-label={nodeName || "Root"}
      className={`treeview${className ? ` ${className}` : " treeview--container"}`}
    >
      {Object.keys(data).map((key) => {
        const value = data[key];
        const isFolder = typeof value === "object";
        const isExpanded = expandedNodes[key];

        return (
          <li key={key} className={"treeview__item"}>
            <div className="treeview__label" onClick={() => handleNodeClick(key, value)}>
              {/* <div className="treeview__label" onClick={() => isFolder && toggleNode(key)}> */}
              {isChevron && isFolder && (
                <div className={`treeview__chevron treeview__chevron${isExpanded ? "--down" : "--right"}`}>
                  {isExpanded ? <ChevronDownLight /> : <ChevronRightLight />}
                </div>
              )}
              <div className="treeview__icon">
                {isFolder ? isExpanded ? <CatalogOpen /> : <CatalogClose /> : <FileClose />}
              </div>
              <span className="treeview__label-name">{key}</span>
            </div>
            {isFolder && (
              <TreeView
                data={value}
                nodeName={key}
                className={`nested${isExpanded ? " expanded" : " collapsed"}`}
                onNodeClick={onNodeClick}
              />
            )}
          </li>
        );
      })}
    </ul>
  );
};
