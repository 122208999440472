import { type CSSProperties } from "react";

type TPages = {
  className?: string;
  style?: CSSProperties;
};

export const Pages = ({ className, style }: TPages) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <mask id="mask0_4_7763" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
      <rect width="24" height="24" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_4_7763)">
      <path
        d="M5.30775 20.5C4.80258 20.5 4.375 20.325 4.025 19.975C3.675 19.625 3.5 19.1974 3.5 18.6923V5.30775C3.5 4.80258 3.675 4.375 4.025 4.025C4.375 3.675 4.80258 3.5 5.30775 3.5H18.6923C19.1974 3.5 19.625 3.675 19.975 4.025C20.325 4.375 20.5 4.80258 20.5 5.30775V18.6923C20.5 19.1974 20.325 19.625 19.975 19.975C19.625 20.325 19.1974 20.5 18.6923 20.5H5.30775ZM5.30775 19H18.6923C18.7692 19 18.8398 18.9679 18.9038 18.9038C18.9679 18.8398 19 18.7692 19 18.6923V5.30775C19 5.23075 18.9679 5.16025 18.9038 5.09625C18.8398 5.03208 18.7692 5 18.6923 5H5.30775C5.23075 5 5.16025 5.03208 5.09625 5.09625C5.03208 5.16025 5 5.23075 5 5.30775V18.6923C5 18.7692 5.03208 18.8398 5.09625 18.9038C5.16025 18.9679 5.23075 19 5.30775 19Z"
        fill="#727272"
      />
      <path
        d="M8.68835 16.515C8.51902 16.6846 8.30893 16.7695 8.0581 16.7695C7.80743 16.7695 7.59718 16.6848 7.42735 16.5155C7.25768 16.3463 7.17285 16.1363 7.17285 15.8855C7.17285 15.6346 7.25752 15.4244 7.42685 15.2547C7.59602 15.085 7.80602 15.0002 8.05685 15.0002C8.30768 15.0002 8.51793 15.0848 8.6876 15.254C8.85727 15.4233 8.9421 15.6334 8.9421 15.8842C8.9421 16.135 8.85752 16.3453 8.68835 16.515Z"
        fill="#727272"
      />
      <path
        d="M8.68835 12.6302C8.51902 12.7999 8.30893 12.8847 8.0581 12.8847C7.80743 12.8847 7.59718 12.8001 7.42735 12.631C7.25768 12.4618 7.17285 12.2518 7.17285 12.001C7.17285 11.7501 7.25752 11.5399 7.42685 11.3702C7.59602 11.2005 7.80602 11.1157 8.05685 11.1157C8.30768 11.1157 8.51793 11.2003 8.6876 11.3695C8.85727 11.5386 8.9421 11.7486 8.9421 11.9995C8.9421 12.2503 8.85752 12.4605 8.68835 12.6302Z"
        fill="#727272"
      />
      <path
        d="M8.68835 8.74571C8.51902 8.91537 8.30893 9.00021 8.0581 9.00021C7.80743 9.00021 7.59718 8.91562 7.42735 8.74646C7.25768 8.57712 7.17285 8.36704 7.17285 8.11621C7.17285 7.86537 7.25752 7.65512 7.42685 7.48546C7.59602 7.31579 7.80602 7.23096 8.05685 7.23096C8.30768 7.23096 8.51793 7.31562 8.6876 7.48496C8.85727 7.65412 8.9421 7.86412 8.9421 8.11496C8.9421 8.36579 8.85752 8.57604 8.68835 8.74571Z"
        fill="#727272"
      />
      <path d="M16.7884 16.6347H11.0961V15.135H16.7884V16.6347Z" fill="#727272" />
      <path d="M16.7884 12.7502H11.0961V11.2502H16.7884V12.7502Z" fill="#727272" />
      <path d="M16.7884 8.86546H11.0961V7.36571H16.7884V8.86546Z" fill="#727272" />
    </g>
  </svg>
);
