import { useEffect, useState } from "react";

import { projectsInstance } from "../../../machines/projects/projects.instance";

import { useAsyncAction } from "../../../utils/useAsyncAction/useAsyncAction";

import { Seo } from "../../atoms/Seo";
import { ButtonNew } from "../../atoms/ButtonNew";

import { Button } from "../../Dashboard/atoms/Button";
import { Navigation } from "../../Dashboard/organisms/Navigation";
import { ProjectSettingsBreadcrumbs } from "../../Dashboard/atoms/ProjectSettingsBreadcrumbs";
import { ProjectSettingsInput } from "../../Dashboard/atoms/ProjectSettingsInput";
import { ProjectSettingsTextArea } from "../../Dashboard/atoms/ProjectSettingsTextArea";

import { GridMiniIcon } from "../../../../assets/svg/grid-mini-icon";

import type { TProjectSettings } from "./ProjectSettings.types";
import type { Project } from "../../../types/types";

import "./ProjectSettings.css";

export const ProjectSettings: TProjectSettings = () => {
  const [activeProject, setActiveProject] = useState<Project>();
  const [originalValues, setOriginalValues] = useState({
    name: "",
    description: "",
    customHead: "",
    customCode: "",
  });
  const [currentValues, setCurrentValues] = useState({
    name: "",
    description: "",
    customHead: "",
    customCode: "",
  });
  const [hasChanges, setHasChanges] = useState(false);
  const { isLoading, handleAsyncAction } = useAsyncAction();

  const projectAddress = () => {
    const isCustomDomainPublished = activeProject?.customDomain?.published;
    const subDomain = activeProject?.subdomain?.address;
    const customDomain = activeProject?.customDomain?.address;
    return isCustomDomainPublished ? customDomain : subDomain;
  };

  useEffect(() => {
    const project = projectsInstance.getSnapshot().context.service.getActiveProject();
    setActiveProject(project);
    if (project) {
      setOriginalValues({
        name: project.name || "",
        description: project.description || "",
        customHead: project.customHead || "",
        customCode: project.customCode || "",
      });
      setCurrentValues({
        name: project.name || "",
        description: project.description || "",
        customHead: project.customHead || "",
        customCode: project.customCode || "",
      });
    }
  }, []);

  useEffect(() => {
    setHasChanges(Object.keys(originalValues).some((key) => originalValues[key] !== currentValues[key]));
  }, [currentValues, originalValues]);

  const handleChange = (field: string, value: string) => {
    setCurrentValues((prevValues) => ({ ...prevValues, [field]: value }));
  };

  const handleClear = (field: string) => {
    setCurrentValues((prevValues) => ({ ...prevValues, [field]: "" }));
  };

  const handleSave = async () => {
    if (activeProject) {
      const projectId = activeProject.projectId;
      const updates: Partial<Omit<Project, "customDomain">> = {};

      if (originalValues.name !== currentValues.name) {
        updates.name = currentValues.name;
      }
      if (originalValues.description !== currentValues.description) {
        updates.description = currentValues.description;
      }
      if (originalValues.customHead !== currentValues.customHead) {
        updates.customHead = currentValues.customHead;
      }
      if (originalValues.customCode !== currentValues.customCode) {
        updates.customCode = currentValues.customCode;
      }

      if (Object.keys(updates).length > 0) {
        try {
          await projectsInstance.getSnapshot().context.service.updateProjectData(projectId, updates);
          setOriginalValues({
            name: currentValues.name,
            description: currentValues.description,
            customHead: currentValues.customHead,
            customCode: currentValues.customCode,
          });
          projectsInstance.getSnapshot().context.service.setSynced(projectId, false);
        } catch (e) {
          console.error(e);
        }
      }
    }
  };

  return (
    <>
      <Seo title="Project Settings - Codejet" />
      <Navigation />
      <div className="project-settings__content">
        <header className="project-setting__header">
          <ProjectSettingsBreadcrumbs title={activeProject?.name || ""} />
          <div className="project-settings__header-container">
            <div className="project-settings__typography">
              <h1 className="project-settings__title">Project settings</h1>
              <h2 className="project-settings__subtitle">Change project properties here</h2>
            </div>
            <Button
              active
              className="project-settings__header-container-btn"
              onClick={() => {
                projectsInstance.send("SELECT_PROJECT", { projectId: activeProject?.projectId });
              }}
            >
              <GridMiniIcon /> Open Visual Editor
            </Button>
          </div>
        </header>
        <div className="project-settings__main-settings">
          <ProjectSettingsInput
            id="project-title"
            label="Project title"
            onChange={(e) => handleChange("name", e.target.value)}
            value={currentValues.name}
          />
          <ProjectSettingsTextArea
            id="project-description"
            label="Page description"
            onChange={(e) => handleChange("description", e.target.value)}
            onClear={() => handleClear("description")}
            value={currentValues.description}
            button
            maxLength={300}
          />
          <div className="project-settings__search-preview">
            <span className="project-settings__label">Search result preview</span>
            <ButtonNew className="project-settings__search-preview-btn" href={projectAddress()}>
              <span>{projectAddress()}</span>
              <span>{currentValues.name}</span>
              <span>{currentValues.description}</span>
            </ButtonNew>
          </div>
        </div>
        <div className="project-settings__custom-code">
          <div className="project-settings__custom-code-header">
            <h3 className="project-settings__title">Custom code</h3>
            <span className="project-settings__subtitle">Add custom code to your website</span>
          </div>
          <ProjectSettingsTextArea
            id="start-of-head"
            label="<head> tag"
            onChange={(e) => handleChange("customHead", e.target.value)}
            onClear={() => handleClear("customHead")}
            value={currentValues.customHead}
            placeholder="Paste your code here"
          />
          <ProjectSettingsTextArea
            id="start-of-body"
            label="<script> tag"
            onChange={(e) => handleChange("customCode", e.target.value)}
            onClear={() => handleClear("customCode")}
            value={currentValues.customCode}
            placeholder="Paste your code here"
          />
        </div>
        <footer className="project-setting__footer">
          <ButtonNew className="project-setting__footer-btn">Cancel</ButtonNew>
          <ButtonNew
            className="project-setting__footer-btn"
            orange
            disabled={!hasChanges}
            onClick={() => handleAsyncAction(() => handleSave())}
            loading={isLoading}
          >
            Save
          </ButtonNew>
        </footer>
      </div>
    </>
  );
};
