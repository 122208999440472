import { SelectorsResultProps } from "@grapesjs/react";
import { Subscriber } from "../../../../../services/abstract/Subscriber/Subscriber.ts";
import { ComponentDefinition } from "grapesjs";

export type SelectorManagerState = {
  open: boolean;
};

type SelectorManagerApi = {
  getSelectedTargets: () => SelectorsResultProps[];
  getStates: () => any[];
  setState: (state: string) => void;
};

export class SelectorManagerService extends Subscriber<SelectorManagerState> {
  state: SelectorManagerState = {
    open: false,
  };

  selectorManager?: any;
  component?: ComponentDefinition;
  sm: any;

  setSelectorManager(selectorManager: any) {
    this.selectorManager = selectorManager;
    this.notifySubscribers();
  }

  selectComponent(component: ComponentDefinition) {
    this.component = component;
    this.notifySubscribers();
  }
  deselectComponent() {
    this.component = undefined;
    this.notifySubscribers();
  }

  open() {
    this.state.open = true;
    this.notifySubscribers();
  }
}
//This need to be loaded first  in editorMachine then used in SelectorManagerMachine
export const selectorManagerInstance = new SelectorManagerService();
