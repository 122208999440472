import { Text } from "./Text.tsx";
import { Trait } from "grapesjs";
import { Select } from "./Select.tsx";
import { Checkbox } from "./Checkbox.tsx";

export const TypeManager = ({ trait }: { trait: Trait }) => {
  if (trait.getType() === "text") {
    return <Text trait={trait} />;
  }

  if (trait.getType() === "select") {
    return <Select trait={trait} />;
  }

  if (trait.getType() === "checkbox") {
    return <Checkbox trait={trait} />;
  }

  return "Type not supported" + trait.getType();
};
