import { Logo } from "../../../assets/svg/Logo.tsx";
import { dashboardInstance } from "../../../machines/dashboard/dashboard.instance.ts";
import { Dashboard } from "../../../assets/svg/Dashboard.tsx";
import { editorInstance, editorService } from "./modules/editor";
import { modalMachineInstance } from "./modules/modal";
import { Insert } from "../../../assets/svg/Insert.tsx";
import { FaceID } from "../../../assets/svg/FaceID.tsx";
import { EyeSolid } from "../../../assets/svg/EyeSolid.tsx";
import { UserScan } from "../../../assets/svg/UserScan.tsx";
import { Undo } from "../../../assets/svg/Undo.tsx";
import { Redo } from "../../../assets/svg/Redo.tsx";
import { Settings } from "../../../assets/svg/Settings.tsx";
import { Code } from "../../../assets/svg/Code.tsx";
import { CodejetRocket } from "../../../assets/svg/CodejetRocket.tsx";
import { projectsInstance } from "../../../machines/projects/projects.instance.ts";

export const TopBarNew = ({ togglePreview }: { togglePreview: () => void }) => {
  const editor = editorService.getEditor();
  const project = projectsInstance.getSnapshot().context.service;
  const activeProject = project.getActiveProject();
  const activeProjectId = activeProject?.projectId;

  return (
    <div className="top-bar">
      <div className="top-bar__left-column">
        <button key="save" onClick={() => {}} title="Codejet Logo">
          <Logo />
        </button>
      </div>
      <div className="top-bar__right-column">
        <button key="dashboard" onClick={() => dashboardInstance.send("PROJECTS")} title="Go to dashboard">
          <Dashboard />
          Dashboard
        </button>

        <select
          name=""
          id=""
          onChange={(e) => {
            editorInstance.send("CHANGE_DEVICE_TYPE", { deviceType: e.target.value });
          }}
          defaultValue={"Desktop"}
        >
          <option value="Desktop">Desktop</option>
          <option value="Tablet">Tablet</option>
          <option value="Mobile">Mobile</option>
        </select>

        <div className="top-bar__cta-wrapper">
          <button
            className={`button-icon${editor?.Commands.isActive("sw-visibility") ? " active" : ""}`}
            key="component_outline"
            onClick={() => {
              editorInstance.send("TOGGLE_OUTLINE");
            }}
            title="Component outline"
          >
            <FaceID />
          </button>
          <button className="button-icon" key="Preview" onClick={togglePreview} title="Preview">
            <EyeSolid />
          </button>
          <button
            className={`button-icon${editor?.Commands.isActive("fullscreen") ? " active" : ""}`}
            // className={`button-icon${document.fullscreenElement !== null ? " active" : ""}`}
            key="fullscreen"
            onClick={() => {
              editorInstance.send("TOGGLE_FULLSCREEN");
            }}
            title="Fullscreen"
          >
            <UserScan />
          </button>
          <button
            className="button-icon"
            key="undo"
            disabled={!editor?.UndoManager.hasUndo()}
            onClick={() => {
              editorInstance.send("UNDO");
            }}
            title="Undo"
          >
            <Undo />
          </button>
          <button
            className="button-icon"
            key="redo"
            disabled={!editor?.UndoManager.hasRedo()}
            onClick={() => {
              editorInstance.send("REDO");
            }}
            title="Redo"
          >
            <Redo />
          </button>
          <button
            className="button-icon"
            key="Settings"
            onClick={() => dashboardInstance.send("PROJECT_SETTINGS", { projectId: activeProjectId })}
            title="Settings"
          >
            <Settings />
          </button>
          <button
            key="view_code"
            className="button--primary"
            onClick={() => {
              modalMachineInstance.send("OPEN", { modalId: "viewCode" });
            }}
            title="View Code"
          >
            <Code /> Code
          </button>
          <button
            key="publish_project"
            className="button--primary"
            onClick={() => {
              modalMachineInstance.send("OPEN", { modalId: "domains" });
            }}
            title="Publish"
          >
            <CodejetRocket /> Publish
          </button>
        </div>
      </div>
    </div>
  );
};
