import { useState } from "react";
import { Close } from "../../../../assets/svg/Close";
import { ColorVariables } from "../ColorVariables/ColorVariables";
import { FontVariables } from "../FontVariables/FontVariables";
import { FontModal } from "../../atoms/FontModal";
import { Button } from "../../atoms/Button";

import { TDesignStyleMenu, DesignStyleMenuTabs } from "./DesignStyleMenu.types";

import "./DesignStyleMenu.css";

export const DesignStyleMenu: TDesignStyleMenu = ({ closePanel }) => {
  const [activePanel, setActivePanel] = useState<DesignStyleMenuTabs>("Colors");

  return (
    <div className="design-style">
      <div className="design-style__content">
        <h2 className="design-style__heading">
          Design Style
          <Button type="icon" onClick={closePanel} iconStart={<Close />} />
        </h2>
        <div className="design-style__tabs">
          <button
            className={`design-style__tab${activePanel === "Colors" ? " design-style__tab--active" : ""}`}
            onClick={() => setActivePanel("Colors")}
          >
            Colors
          </button>
          <button
            className={`design-style__tab${activePanel === "Typography" ? " design-style__tab--active" : ""}`}
            onClick={() => setActivePanel("Typography")}
          >
            Typography
          </button>
        </div>
        <div className="design-style__site-style site-style">
          <div className="site-style__content">
            <div className="site-style__fonts">
              <p className="site-style__fonts-primary">Site style</p>
              <p className="site-style__font-secondary">
                This theme is used <br /> across your site
              </p>
            </div>
            <div className="site-style__colors">
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
        <div className="design-style__panels">
          {activePanel === "Colors" && <ColorVariables />}
          {activePanel === "Typography" && <FontVariables />}
        </div>
      </div>
      <FontModal />
    </div>
  );
};
