import { View } from "../modal.service.ts";
import { ModalViewDefault } from "./default.tsx";
import { ViewCodeModal } from "./ViewCode/ViewCode.tsx";
import { DomainsModal } from "./Domains/Domains.tsx";
import { CustomDomainModal } from "./CustomDomain/CustomDomain.tsx";
import { UnpublishDomainModal } from "./UnpublishDomain/UnpublishDomain.tsx";

export const views: View[] = [
  {
    id: "defaultView",
    component: ModalViewDefault,
  },
  {
    id: "viewCode",
    component: ViewCodeModal,
  },
  {
    id: "domains",
    component: DomainsModal,
  },
  {
    id: "customDomain",
    component: CustomDomainModal,
  },
  {
    id: "unpublishDomain",
    component: UnpublishDomainModal,
  },
];
