import { type CSSProperties } from "react";

type TAiStars = {
  className?: string;
  style?: CSSProperties;
};

export const AiStars = ({ className, style }: TAiStars) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M4.24599 4.54681L5.05921 2.18122L5.87242 4.5468C5.983 4.86848 6.22601 5.14216 6.55892 5.27476L8.91286 6.21234L6.55892 7.14992L6.81794 7.80023L6.55892 7.14992C6.22601 7.28252 5.983 7.5562 5.87242 7.87787L5.05921 10.2435L4.24599 7.87787C4.13541 7.5562 3.8924 7.28252 3.55949 7.14992L3.30047 7.80023L3.55949 7.14992L1.20555 6.21234L3.55949 5.27476C3.8924 5.14216 4.13541 4.86848 4.24599 4.54681ZM3.21634 18.1671L3.59037 19.2551C3.70096 19.5768 3.94396 19.8505 4.27687 19.9831L5.44394 20.4479L4.27687 20.9128C3.94396 21.0454 3.70096 21.3191 3.59037 21.6407L3.21634 22.7288L2.8423 21.6407C2.73172 21.3191 2.48871 21.0454 2.1558 20.9128L0.988741 20.4479L2.1558 19.9831C2.48871 19.8505 2.73172 19.5768 2.8423 19.2551L3.21634 18.1671Z"
      fill="#71717A"
      stroke="#71717A"
      strokeWidth="1.4"
    />
    <path
      d="M15.0878 11.4066L16.5744 7.08211L18.0611 11.4066C18.1716 11.7283 18.4147 12.0019 18.7476 12.1345L22.9214 13.797L18.7476 15.4594C18.4147 15.592 18.1716 15.8657 18.0611 16.1874L16.5744 20.5119L15.0878 16.1874C14.9772 15.8657 14.7342 15.592 14.4013 15.4594L10.2275 13.797L14.4013 12.1345L14.1423 11.4842L14.4013 12.1345C14.7342 12.0019 14.9772 11.7283 15.0878 11.4066Z"
      fill="#71717A"
      stroke="#71717A"
      strokeWidth="1.4"
    />
  </svg>
);
