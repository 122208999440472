import { assign, createMachine } from "xstate";
import { BlockProperties, Editor, grapesjs } from "grapesjs";
import { editorConfig } from "../../editor.config.ts";
import { EditorService } from "./editor.service.ts";
import { pagesService } from "../pages/pages.service.ts";
import { projectsService } from "../../../../../machines/projects/projects.instance.ts";
import { assetManagerInstance, assetManagerService } from "../asset-manager";
import { modalMachineInstance } from "../modal/index.ts";
import { extractVariables } from "../../utils/extract-variables.ts";
import { UserService } from "../../../../../services/UserService/UserService.ts";

const userService = UserService.getInstance();

export type EditorContext = {
  editor?: Editor;
  service: EditorService;
  domainToUnpublish: "subdomain" | "customDomain";
};
export const editorMachine = (service: EditorService) =>
  createMachine<EditorContext>(
    {
      /** @xstate-layout N4IgpgJg5mDOIC5QGEBKBRAggFXQfQFUBldVPAMQHlUBZAOiO01WwGIBtABgF1FQAHAPawAlgBcRggHZ8QAD0QBGACzK6ADgBM6xYoDsmzgDYjATlPqjAGhABPJQGZFGsw6169p5Z3UBWAL7+NmhYuIQkZFS0dOTo2MgAEngAigTojACSlAByRKwQ0mB0IlIAboIA1kUhOPjEpBTU9LHxSanp2Fm5CCXlAMYAhhLSXNyjskKiwzJI8ojKRmqmDnrKpoqceoq+yirKNvYIXhqm2pp66uoOvtu+eoHBGLXhDVHNcYkpaZk5eWAATv9BP86PwADZDABmwIAtnQamF6pEmjEPm1vp1fj0yoJBtNRuNZpNxJIZqAFAgFksVmsNlsdnsDogjA5OHRfL5zL5Wb4jOo9EYHiAEXUIo1ou0frlWERMAA1fCYXIAdVIhIEwhJ0lkFL8ahZ6mMmx2nB8miZCF8miMdE43lWpiM1sUmgcgqCwqeiLFbzoksx0uy6AAGtgvh0uuqQMTpjrEHq6KZjJp9G5tEYLhbdr5E9yXeY6ZZVkKRS9kRKMV08gAFDByrLEcNSqMx0lxhAJpNO1NaIvqC36NTKPOGLzWvnKEte0WvFGyhV4JVEVWofKFYo4qrw6dl8X0eeKlWkbH9Iakgk8Caa2OzXXHZTqZROF1GO7D812eZXdlu3ycTQ7H+r6KFOoQzuW9AZEQFAZNkmAADJNgGHCXkS15trejj6HQygplopp+CY-afpSdx0BciwsnonCKI+tEBB6pZInudBQTBcGIf6XQcIovBoVMGHklheg4Xhhg+LyfJZro5GWAsnB3Ga5igc8zG+kGoZIdxBRSEUvSVNUO5qSiGlhlxOQnriZ4jDwLbodqmEIIoTimHQ1ocuoFhJvoqwWucDiJgKkkrB4r6aCp3qztEtboPWlCNuZ2RrrpG7lFuTE+iiMVxQllYWfpeLnrZqEagJDlCR2RjODcHlbGczkWjoOHmOYbgCk6VoMY8YG7r6MXVswh7LqQeQ6Xpm6GT1xnRRgA0YIuR6oEQlmFTZYwldG9lknMTmrJo7JOu+2zeA4pxZmRnBugs5zaOYPgReBLFLiu0FPAAIgAmjK6DZG9C3DUtdlldtFIpqdOF8nop18qYkm+OdObcpRmxXNolhdZ6U2ZdEJC-f9L3JeNaWTap2P7j9f3PSNK3WVIF58aVWog4gAHg3cOjXGmdpeFmhi2pynl7KoZheA9vVzhT+MjawAJAiC4JQrC25Y1F5N41TS00-ixUM5twPtqzrns853L4Ws+wkTcAU0ay9EbFbIFClIggQHAsgZarV7645AC01gkX7tqmqavh+JsejclDGMexBDBMCwXtM+2uEDqyLjLHyOyaK6OwOGL03vK0Wm-InN4Va6rmnLRHiGJcnkDpsdBul4l1Vd4hrWvnZN+nluSl4JO0mCJVePrDEftwOig2lVY5uMYnmbF3qsMPKQ0rv35U7YWOHcsapzjwKWarLmTg3Pol0CtsS+x2x5CwQhxfZBvzNORsbKGgYKb6NnSbEYcuw2mHM+S+yxtjqGvixUyj9n7tm2BsOgh1TDUVoqcMKflLh0F0EgiO5gPDtwgX1OsDZoKJRgY5beQC97nB2IfEisMEFPmtEmM+LpuQEKyrNQaUslpkIqnAtkiDkGeXch+f+Dh9pI25DdWG11o5GW7hrV6WBPq8K3rDfaCx+TnFOJyXQWZxE-lfOIgwMi27sJxpLRRqjQbiJzNsQ6eY3QrH9mIiRV0HBPmwRcSw5jmiwSggkdAb1rEsyhs4R0-JqKcHWBmeGJFVBsiRqoZQeC-DUTkSrWOpBUDUBCQgfy4TIZRJiRHC0NxXLWlZNE9GtEHB50CP4IAA */
      predictableActionArguments: true,
      id: "ALPHA_O",
      initial: "START",
      context: {
        service,
        domainToUnpublish: "subdomain",
      },
      states: {
        START: {
          invoke: {
            src: "initEditor",
            onDone: {
              target: "MOUNT_BLOCKS",
            },
          },
        },

        MOUNT_BLOCKS: {
          invoke: {
            src: "mountBlocks",
            onDone: {
              target: "MOUNT_COMPONENTS",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        MOUNT_COMPONENTS: {
          invoke: {
            src: "mountComponents",
            onDone: {
              target: "INIT_ASSET_MANAGER_SERVICE",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        INIT_ASSET_MANAGER_SERVICE: {
          invoke: {
            src: "initAssetManagerService",
            onDone: {
              target: "LOAD_PROJECT",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        LOAD_PROJECT: {
          invoke: {
            src: "loadProject",
            onDone: {
              target: "ADD_SCRIPTS",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        ADD_SCRIPTS: {
          invoke: {
            src: "addScripts",
            onDone: {
              target: "SEND_PAGES_TO_PAGE_SERVICE",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        SEND_PAGES_TO_PAGE_SERVICE: {
          invoke: {
            src: "sendPagesToPageService",
            onDone: {
              target: "READY",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        READY: {
          entry: "notify",
          on: {
            LOAD_PROJECT: "LOAD_PROJECT",
            DRAG_START: "DRAG_START",
            DRAG_END: "DRAG_END",
            SHOW_ADD_NEW_ELEMENT_PANEL: "SHOW_ADD_NEW_ELEMENT_PANEL",
            CLOSE_ADD_NEW_ELEMENT_PANEL: "CLOSE_ADD_NEW_ELEMENT_PANEL",
            PUBLISH_PROJECT: "PUBLISH_PROJECT",
            PUBLISH_UPDATES: "PUBLISH_UPDATES",
            UNPUBLISH_PROJECT: "UNPUBLISH_PROJECT",
            DOMAIN_TO_UNPUBLISH: "DOMAIN_TO_UNPUBLISH",
            SAVE_CUSTOM_DOMAIN: "SAVE_CUSTOM_DOMAIN",
            CHANGE_DEVICE_TYPE: "CHANGE_DEVICE_TYPE",
            TOGGLE_PREVIEW: "TOGGLE_PREVIEW",
            UNDO: "UNDO",
            REDO: "REDO",
            TOGGLE_FULLSCREEN: "TOGGLE_FULLSCREEN",
            TOGGLE_OUTLINE: "TOGGLE_OUTLINE",
          },
        },

        PUBLISH_UPDATES: {
          invoke: {
            src: "publishUpdates",
            onDone: {
              target: "READY",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        PUBLISH_PROJECT: {
          invoke: {
            src: "publishProject",
            onDone: {
              target: "READY",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        DOMAIN_TO_UNPUBLISH: {
          entry: assign({
            domainToUnpublish: (context, event) => event.domainToUnpublish,
          }),
          always: [
            {
              target: "READY",
            },
          ],
        },

        UNPUBLISH_PROJECT: {
          invoke: {
            src: "unpublishProject",
            onDone: {
              target: "READY",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        SAVE_CUSTOM_DOMAIN: {
          invoke: {
            src: "saveCustomDomain",
            onDone: {
              target: "READY",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        CHANGE_DEVICE_TYPE: {
          invoke: {
            src: "changeDeviceType",
            onDone: {
              target: "READY",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        SHOW_ADD_NEW_ELEMENT_PANEL: {
          invoke: {
            src: "showAddNewElementPanel",
            onDone: {
              target: "READY",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        CLOSE_ADD_NEW_ELEMENT_PANEL: {
          invoke: {
            src: "hideAddNewElementPanel",
            onDone: {
              target: "READY",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        DRAG_END: {
          invoke: {
            src: "dragEnd",
            onDone: {
              target: "READY",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        DRAG_START: {
          invoke: {
            src: "dragStart",
            onDone: {
              target: "READY",
            },
            onError: {
              target: "ERROR",
            },
          },
        },
        TOGGLE_PREVIEW: {
          invoke: {
            src: "togglePreview",
            onDone: {
              target: "READY",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        UNDO: {
          invoke: {
            src: "undo",
            onDone: {
              target: "READY",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        REDO: {
          invoke: {
            src: "redo",
            onDone: {
              target: "READY",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        TOGGLE_FULLSCREEN: {
          invoke: {
            src: "toggleFullscreen",
            onDone: {
              target: "READY",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        TOGGLE_OUTLINE: {
          invoke: {
            src: "toggleOutline",
            onDone: {
              target: "READY",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        FINISH: {
          type: "final",
        },

        ERROR: {},
        //
      },
    },
    {
      actions: {
        notify: (context) => {
          context.service.notifySubscribers();
        },
      },
      services: {
        publishUpdates: async ({ service }) => {
          const editor = service.getEditor();
          if (!editor) return Promise.resolve();

          const activeProject = projectsService.getActiveProject();
          const canPurchase = userService.canPurchaseSubscription();

          if (activeProject) {
            const history = await projectsService.getHistory(activeProject.projectId);
            const customCode = (history.metadata.customCode as string) || undefined;
            const customHead = (history.metadata.customHead as string) || undefined;
            const title = (history.metadata.name as string) || undefined;
            const description = (history.metadata.description as string) || undefined;

            const codejetBadgeScript = `<style>.codejet-badge {position: fixed;right: 1rem;bottom: 1rem;z-index: 9999;} @media (max-width: 768px) { .codejet-badge { right: 0.5rem; bottom: 0.5rem;}} </style>
            <script> document.addEventListener("DOMContentLoaded", function() { const codejetBadge = document.createElement("div"); codejetBadge.classList.add("codejet-badge");
                codejetBadge.innerHTML = '<a href="https://codejet.ai" target="_blank" style="display: flex; align-items: center; background: #FFF; color: #27272A; font-size: 12px; line-height: 150%; font-weight: 600; font-family: sans-serif; text-decoration: none; padding: 0.5rem; border: 1px solid #E4E4E4; border-radius: 0.5rem">' + '<svg width="21" height="21" viewBox="0 0 21 21" fill="none" style="margin-right: 0.5rem;" xmlns="http://www.w3.org/2000/svg">' + '<rect width="21" height="21" rx="4" fill="#FF4900"/>' + '<path d="M14.5625 9.14657V13.2085L11.8542 10.4997L11.8555 7.7929H9.14583V10.5002L6.4375 13.2085V9.14657L10.5024 5.08301L14.5625 9.14657Z" fill="white"/>' +
'<path d="M11.8542 15.9168V13.2087H9.16911V15.9168H11.8542Z" fill="white"/>' + '<path d="M14.5625 9.14657V13.2085L11.8542 10.4997L11.8555 7.7929H9.14583V10.5002L6.4375 13.2085V9.14657L10.5024 5.08301L14.5625 9.14657Z" fill="white"/>' + '</svg>' + 'Made with Codejet</a>';
                document.body.appendChild(codejetBadge);
              });
            </script>
            `;

            //TODO manipulate html before save
            activeProject.projectHead = `
            <head>
            <title>${title} | Powered by Codejet.ai</title>
            <meta name="description" content="${description}">
            <meta charset="UTF-8">
            <link rel="stylesheet" href="/figma-upload/reset.css" />
            <link rel="stylesheet" href="/styles.css" />
            <link rel="stylesheet" href="/variables.css" />
            ${customHead || ""}
            ${canPurchase ? codejetBadgeScript : ""}
            </head>`;
            // editor.Canvas.getDocument().head.outerHTML.match(/<head>[\s\S]*<\/head>/)?.[0] || "<head></head>";
            // opening tags for html
            activeProject.projectHead = "<html>" + activeProject.projectHead;
            // closing tags for html
            activeProject.projectCloseTag = "\n</html>";

            //Save CSS and HTML in bucket files

            const customTemplate = `
              document.addEventListener("DOMContentLoaded", function() {
                ${customCode}
              });
            `;

            const isCustomCode = customCode !== undefined;

            const replaceScriptTag = (html: string, scriptSrc: string) => {
              const newScriptTag = `<script src="${scriptSrc}"></script>`;
              const scriptTagRegex = /<script\b[^>]*>([\s\S]*?)<\/script>/i;
              if (scriptTagRegex.test(html)) {
                return html.replace(scriptTagRegex, newScriptTag);
              } else {
                return html.replace("</body>", `${newScriptTag}\n</body>`);
              }
            };

            const projectBody = isCustomCode ? replaceScriptTag(editor.getHtml(), "index.js") : editor.getHtml();
            // remove class="iframe-no-pointer" from Video component
            const clearedBody = projectBody.replace(/class="iframe-no-pointer"/g, "");

            activeProject.html = activeProject.projectHead + clearedBody + activeProject.projectCloseTag;
            // activeProject.html = activeProject.projectHead + editor.getHtml() + activeProject.projectCloseTag;
            // store data so it can be saved
            try {
              // store chnages so they can be saved
              // await editor?.store();
              // store files in OVH
              await projectsService.saveProjectHtml(activeProject.projectId, activeProject.html);

              const css =
                editor.getCss({
                  keepUnusedStyles: true,
                }) || "";

              const { variables, styles } = extractVariables(css, [
                "local-styles-variables",
                "local-text-styles-variables",
              ]);

              // save variables.css
              await projectsService.saveProjectCss(activeProject.projectId, variables || "", "variables.css");
              // save styles.css
              await projectsService.saveProjectCss(activeProject.projectId, styles || "", "styles.css");
              await projectsService.saveProjectScript(activeProject.projectId, customTemplate || "");

              // update project meta data
              await projectsService.setSynced(activeProject?.projectId, true);
              // invalidate domain
              await projectsService.invalidateDomain(activeProject.projectId);
            } catch (error) {
              console.error(error);
            }
          } else {
            console.log("No active project");
          }
          return Promise.resolve();
        },

        publishProject: async ({ service }, { customDomain }) => {
          const editor = service.getEditor();
          if (!editor) return Promise.resolve();

          const activeProject = projectsService.getActiveProject();
          const canPurchase = userService.canPurchaseSubscription();

          if (activeProject) {
            const history = await projectsService.getHistory(activeProject.projectId);
            const customCode = (history.metadata.customCode as string) || undefined;
            const customHead = (history.metadata.customHead as string) || undefined;
            const title = (history.metadata.name as string) || undefined;
            const description = (history.metadata.description as string) || undefined;

            const codejetBadgeScript = `<style>.codejet-badge {position: fixed;right: 1rem;bottom: 1rem;z-index: 9999;} @media (max-width: 768px) { .codejet-badge { right: 0.5rem; bottom: 0.5rem;}} </style>
            <script> document.addEventListener("DOMContentLoaded", function() { const codejetBadge = document.createElement("div"); codejetBadge.classList.add("codejet-badge");
                codejetBadge.innerHTML = '<a href="https://codejet.ai" target="_blank" style="display: flex; align-items: center; background: #FFF; color: #27272A; font-size: 12px; line-height: 150%; font-weight: 600; font-family: sans-serif; text-decoration: none; padding: 0.5rem; border: 1px solid #E4E4E4; border-radius: 0.5rem">' + '<svg width="21" height="21" viewBox="0 0 21 21" fill="none" style="margin-right: 0.5rem;" xmlns="http://www.w3.org/2000/svg">' + '<rect width="21" height="21" rx="4" fill="#FF4900"/>' + '<path d="M14.5625 9.14657V13.2085L11.8542 10.4997L11.8555 7.7929H9.14583V10.5002L6.4375 13.2085V9.14657L10.5024 5.08301L14.5625 9.14657Z" fill="white"/>' +
'<path d="M11.8542 15.9168V13.2087H9.16911V15.9168H11.8542Z" fill="white"/>' + '<path d="M14.5625 9.14657V13.2085L11.8542 10.4997L11.8555 7.7929H9.14583V10.5002L6.4375 13.2085V9.14657L10.5024 5.08301L14.5625 9.14657Z" fill="white"/>' + '</svg>' + 'Made with Codejet</a>';
                document.body.appendChild(codejetBadge);
              });
            </script>
            `;

            //TODO manipulate html before save
            activeProject.projectHead = `
            <head>
            <title>${title} | Powered by Codejet.ai</title>
            <meta name="description" content="${description}">
            <meta charset="UTF-8">
            <link rel="stylesheet" href="/figma-upload/reset.css" />
            <link rel="stylesheet" href="/styles.css" />
            <link rel="stylesheet" href="/variables.css" />
            ${customHead || ""}
            ${canPurchase ? codejetBadgeScript : ""}
            </head>`;
            // editor.Canvas.getDocument().head.outerHTML.match(/<head>[\s\S]*<\/head>/)?.[0] || "<head></head>";
            // opening tags for html
            activeProject.projectHead = "<html>" + activeProject.projectHead;
            // closing tags for html
            activeProject.projectCloseTag = "\n</html>";

            //Save CSS and HTML in bucket files

            const customTemplate = `
              document.addEventListener("DOMContentLoaded", function() {
                ${customCode}
              });
            `;

            const isCustomCode = customCode !== undefined;

            const replaceScriptTag = (html: string, scriptSrc: string) => {
              const newScriptTag = `<script src="${scriptSrc}"></script>`;
              const scriptTagRegex = /<script\b[^>]*>([\s\S]*?)<\/script>/i;
              if (scriptTagRegex.test(html)) {
                return html.replace(scriptTagRegex, newScriptTag);
              } else {
                return html.replace("</body>", `${newScriptTag}\n</body>`);
              }
            };

            const projectBody = isCustomCode ? replaceScriptTag(editor.getHtml(), "index.js") : editor.getHtml();
            // remove class="iframe-no-pointer" from Video component
            const clearedBody = projectBody.replace(/class="iframe-no-pointer"/g, "");

            activeProject.html = activeProject.projectHead + clearedBody + activeProject.projectCloseTag;

            await projectsService.saveProjectHtml(activeProject.projectId, activeProject.html);
            await projectsService.saveProjectScript(activeProject.projectId, customTemplate || "");

            const css =
              editor.getCss({
                keepUnusedStyles: true,
              }) || "";

            const { variables, styles } = extractVariables(css, [
              "local-styles-variables",
              "local-text-styles-variables",
            ]);

            // save variables.css
            await projectsService.saveProjectCss(activeProject.projectId, variables || "", "variables.css");
            // save styles.css
            await projectsService.saveProjectCss(activeProject.projectId, styles || "", "styles.css");
            // update project meta data
            await projectsService.setSynced(activeProject?.projectId, true);

            try {
              let projectData;
              if (customDomain) {
                projectData = {
                  projectId: activeProject.projectId,
                  customDomain: activeProject.customDomain.address,
                };
              } else {
                projectData = {
                  projectId: activeProject.projectId,
                };
              }

              await projectsService.publishProject(projectData);
              modalMachineInstance.send("CHANGE_VIEW", { viewId: "domains" });
            } catch (error) {
              console.error(error);
            }
          } else {
            console.log("No active project");
          }

          return Promise.resolve();
        },

        unpublishProject: async ({ service, domainToUnpublish }) => {
          const editor = service.getEditor();
          if (!editor) return Promise.resolve();

          const activeProject = projectsService.getActiveProject();

          if (activeProject) {
            try {
              let projectData;
              if (domainToUnpublish === "customDomain") {
                projectData = {
                  projectId: activeProject.projectId,
                  customDomain: activeProject.customDomain.address,
                };
              } else {
                projectData = {
                  projectId: activeProject.projectId,
                };
              }

              await projectsService.unpublishProject(projectData);
              modalMachineInstance.send("CHANGE_VIEW", { viewId: "domains" });
            } catch (error) {
              console.error(error);
            }
          } else {
            console.log("No active project");
          }

          return Promise.resolve();
        },

        saveCustomDomain: async ({ service }, { customDomain }) => {
          const editor = service.getEditor();
          if (!editor) return Promise.resolve();

          const activeProject = projectsService.getActiveProject();

          if (activeProject) {
            await projectsService.updateProjectData(activeProject.projectId, { customDomain });
          } else {
            console.log("No active project");
          }

          return Promise.resolve();
        },

        sendPagesToPageService: ({ service }) => {
          const editor = service.getEditor();
          if (!editor) return Promise.resolve();

          const pages = editor.Pages.getAll();
          //Editor has be initialised to send pages to pagesMachine
          pagesService.state = pages;
          pagesService.notifySubscribers();

          return Promise.resolve();
        },

        showAddNewElementPanel: ({ service }) => {
          service.addNewElementPanelVisible = true;
          //
          // return Promise.resolve();
          return Promise.resolve();
        },

        hideAddNewElementPanel: ({ service }) => {
          service.addNewElementPanelVisible = false;

          return Promise.resolve();
        },

        //add resetCss script when project is loaded
        addScripts: async ({ service }) => {
          const editor = service.getEditor();
          //TODO add here all scripts from project settings
          if (editor) {
            projectsService.scripts.forEach((script) => {
              editor.Canvas.getDocument().head.innerHTML += script;
            });
            // add custom code from history
            editor.on("load", async () => {
              const activeProject = projectsService.getActiveProject();
              if (activeProject !== undefined) {
                const history = await projectsService.getHistory(activeProject.projectId);
                const customCode = (history.metadata.customCode as string) || undefined;
                if (customCode !== undefined) {
                  const scriptCode = `
                    if (document.readyState !== "loading") {
                      ${customCode}
                    }
                  `;

                  const scriptTagId = "customScriptTag"; // Unikalny identyfikator dla skryptu
                  const script = editor.Canvas.getDocument().getElementById(scriptTagId);
                  if (script) {
                    script.textContent = scriptCode;
                  } else {
                    const script = document.createElement("script");
                    script.id = scriptTagId;
                    script.type = "text/javascript";
                    script.textContent = scriptCode;
                    editor.Canvas.getDocument().head.appendChild(script);
                  }

                  const customHead = (history.metadata.customHead as string) || undefined;
                  if (customHead) {
                    editor.Canvas.getDocument().head.innerHTML += customHead;
                  }
                }
              }
            });
          }

          return Promise.resolve();
        },

        loadProject: async ({ service }) => {
          //TODO split into single steps in machine
          const editor = service.getEditor();
          if (!editor) return Promise.resolve();

          try {
            //if there is history of project saved as JSON load it
            await editor.load({ projectId: projectsService.getActiveProject()?.projectId });
            // throw new Error("No history found");
          } catch (e) {
            // if there is no history of project saved as JSON load html and css
            const activeProject = projectsService.getActiveProject();
            if (!activeProject) return;

            const html = await projectsService.getHtmlByProjectId(activeProject.projectId);
            activeProject.html = html;
            activeProject.projectHead =
              activeProject.html.match(/<html>[\s\S]*<head>[\s\S]*<\/head>/)?.[0] || "<html><head></head>";
            activeProject.projectCSSResetLink = activeProject.html.match(/<link.*?reset\.css.*?>/)?.[0] || "";
            activeProject.projectBody = activeProject.html.match(/<body>[\s\S]*<\/body>/)?.[0] || "<body></body>";
            activeProject.projectCloseTag = "\n</html>";

            //load css from active project
            activeProject.css = await projectsService.getCssByProjectId(activeProject.projectId);
            activeProject.variables = await projectsService.getVariablesByProjectId(activeProject.projectId);

            //load html from active project
            editor.DomComponents.clear();
            editor.addComponents(activeProject.projectBody);
            editor.Canvas.getDocument().head.innerHTML += activeProject.projectCSSResetLink;
            //load css from active project
            const combinedStyle = `${activeProject.variables} ${activeProject.css}`;
            editor.addStyle(combinedStyle);
            // need to clear undo manager after loading project so can't remove html/css from page
            editor.UndoManager.clear();
          }

          return Promise.resolve();
        },

        // saveProject: async ({ service }) => {
        //TODO this is to save project as HTML and CSS when publish
        // const editor = service.getEditor();
        // if (!editor) return Promise.resolve();
        //
        // const activeProject = projectsService.getActiveProject();
        //
        // if (activeProject) {
        //   activeProject.html = activeProject.projectHead + editor.getHtml() + activeProject.projectCloseTag;
        //   projectsService.saveProjectHtml(activeProject.projectId, activeProject.html);
        //   projectsService.saveProjectCss(activeProject.projectId, editor.getCss() || "");
        // } else {
        //   console.log("No active project");
        // }

        // return Promise.resolve();
        // },

        initEditor: (context) => {
          const editor = grapesjs.init(editorConfig);
          context.service.setEditor(editor);

          editor.on("block:custom", (props) => {
            service.setBlocksModel(props);
          });

          editor.on("layer:custom", () => {
            const layerManagerContainer = document.getElementById("gjs-layer-manager");
            if (layerManagerContainer) {
              layerManagerContainer.appendChild(editor.LayerManager.render());
            }
          });

          //TODO move to plugin and create function to inject projectService
          editor.Storage.add("remote", {
            async load() {
              //get active project
              const activeProject = projectsService.getActiveProject();

              if (activeProject) {
                //first load from local storage
                //TODO check how to delete all data from local storage when project is deleted or user logs out
                const localData = sessionStorage.getItem("project-config-" + activeProject?.projectId);
                if (localData) {
                  const data = JSON.parse(localData);
                  //Add scripts for next step
                  //TODO add meta field for projectService
                  projectsService.scripts = data.scripts;
                  return data.config;
                }

                //if history not empty load history
                const history = await projectsService.getHistory(activeProject?.projectId);
                const historyData = history.pop(); // TODO check if this is correct
                if (historyData) {
                  //Add scripts for next step
                  //TODO add meta field for projectService
                  projectsService.scripts = historyData.scripts;
                  return historyData.config;
                }
                throw new Error("No history found");
              }
            },

            async store(data) {
              //get active project ID and save project JSON
              //TODO locally store project JSON
              //TODO send only patch on JSON
              //this is a bit tricky but it's only way to use autosave feature
              const activeProject = projectsService.getActiveProject();

              //get all scripts from editor head as strings
              const scripts = Array.from(editor.Canvas.getDocument().head.children).map((script) => script.outerHTML);

              if (activeProject) {
                //save to local storage
                sessionStorage.setItem(
                  "project-config-" + activeProject?.projectId,
                  JSON.stringify({
                    scripts,
                    config: data,
                  })
                );
                //if history not empty load history
                await projectsService.saveHistory(activeProject?.projectId, { config: data, scripts });
                if (activeProject.isSynced !== false) {
                  await projectsService.setSynced(activeProject?.projectId, false);
                }
              }
            },
          });

          // TODO move to own service
          // editor.on("selector:custom", (props) => {
          //   console.log("selector:custom", props);
          //   selectorManagerInstance.setSelectorManager(editor.Selectors);
          //   selectorManagerInstance.open();
          // });
          // editor.on("component:selected", (props) => {
          //   console.log(props);
          //   selectorManagerInstance.selectComponent(props);
          // });
          // editor.on("component:deselected", (props) => {
          //   selectorManagerInstance.deselectComponent();
          // });

          return Promise.resolve();
        },

        initAssetManagerService: ({ service }) => {
          const editor = service.getEditor();
          if (!editor) return Promise.resolve();

          editor.on("asset:custom", (props) => {
            assetManagerService.setAssetManager(props);
            assetManagerInstance.send("INIT");
          });

          editor.on("component:selected", (props) => {
            if (props.attributes.tagName === "img" || props.attributes.tagName === "video") {
              assetManagerInstance.send("SELECT_COMPONENT", { component: props });
            }
          });

          editor.on("component:deselected", (props) => {
            if (props.attributes.tagName === "img" || props.attributes.tagName === "video") {
              assetManagerInstance.send("DESELECT_COMPONENT");
            }
          });

          return Promise.resolve();
        },

        mountComponents: ({ service }) => {
          const editor = service.getEditor();
          if (!editor) return Promise.resolve();

          for (const componentDef of service.componentsDef) {
            editor.Components.addType(componentDef.id, componentDef.props);
          }

          return Promise.resolve();
        },

        mountBlocks: ({ service }) => {
          const editor = service.getEditor();
          if (!editor) return Promise.resolve();

          //mount block from service to editor
          for (const block of service.blocks) {
            if (block.id) {
              editor.Blocks.add(block.id, block);
            }
          }

          //Load blocks added from plugins to service
          const blockMap = editor.Blocks.getAllMap();
          for (const key in blockMap) {
            if (service.blocks.find((block) => block.id === key)) continue;
            service.blocks.push(blockMap[key].attributes as BlockProperties);
          }

          return Promise.resolve();
        },

        //appendService
        dragEnd: ({ service }, { id }) => {
          const editor = service.getEditor();
          if (!editor) return Promise.resolve();

          const block = service.blocksModel.blocks.find((block) => block.id === id);
          if (block) {
            service.blocksModel.dragStop(block);
          }
          return Promise.resolve();
        },

        dragStart: ({ service }, { id }) => {
          const editor = service.getEditor();
          if (!editor) return Promise.resolve();

          const block = service.blocksModel.blocks.find((block) => block.id === id);
          if (block) {
            service.blocksModel.dragStart(block);
          }
          return Promise.resolve();
        },

        changeDeviceType: ({ service }, { deviceType }) => {
          const editor = service.getEditor();
          if (!editor) return Promise.resolve();

          editor.setDevice(deviceType);
          return Promise.resolve();
        },

        togglePreview: ({ service }) => {
          const editor = service.getEditor();
          if (!editor) return Promise.resolve();

          if (editor.Commands.isActive("preview")) {
            editor.DomComponents.getWrapper()?.onAll((comp) => comp.is("text") && comp.set({ editable: true }));
            // add pointer events none to video iframes
            const iframes = editor.getWrapper()?.find(".video-wrapper iframe");
            iframes?.forEach((iframe) => {
              iframe.addClass("iframe-no-pointer");
            });
            editor.stopCommand("preview");
          } else {
            // remove pointer events from video iframes
            const iframes = editor.getWrapper()?.find(".video-wrapper iframe");
            iframes?.forEach((iframe) => {
              iframe.removeClass("iframe-no-pointer");
            });

            editor.stopCommand("sw-visibility");
            editor.runCommand("preview");
            editor.DomComponents.getWrapper()?.onAll((comp) => comp.is("text") && comp.set({ editable: false }));
          }

          return Promise.resolve();
        },

        undo: ({ service }) => {
          const editor = service.getEditor();
          if (!editor) return Promise.resolve();

          editor.UndoManager.undo();

          return Promise.resolve();
        },

        redo: ({ service }) => {
          const editor = service.getEditor();
          if (!editor) return Promise.resolve();

          editor.UndoManager.redo();

          return Promise.resolve();
        },

        toggleFullscreen: ({ service }) => {
          const editor = service.getEditor();
          if (!editor) return Promise.resolve();

          if (editor.Commands.isActive("fullscreen")) {
            editor.stopCommand("fullscreen");
          } else {
            editor.runCommand("fullscreen", { target: ".alpha-o" });
          }

          return Promise.resolve();
        },

        toggleOutline: ({ service }) => {
          const editor = service.getEditor();
          if (!editor) return Promise.resolve();

          if (editor.Commands.isActive("sw-visibility")) {
            editor.stopCommand("sw-visibility");
          } else {
            editor.runCommand("sw-visibility");
          }

          return Promise.resolve();
        },
      },

      guards: {
        //appendGuards
      },
    }
  );
