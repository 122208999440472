import { AlertTriangle } from "../../../../../../../assets/svg/alert-triangle";

import { TModal } from "./Modal.types";

import "./Modal.css";

export const Modal: TModal = ({ isOpen, onClick, onClose }) => {
  if (!isOpen) return null;

  const handleKeyPropagation = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter" || event.key === " ") {
      event.stopPropagation();
    }
  };

  return (
    <div
      className="project-card-modal__backdrop"
      role="menu"
      tabIndex={-1}
      onClick={(e) => e.stopPropagation()}
      onKeyDown={handleKeyPropagation}
    >
      <div className="project-card-modal__content">
        <div className="project-card-modal__header">
          <div className="project-card-modal__icon">
            <AlertTriangle />
          </div>
          <h4 className="project-card-modal__title">Delete this project?</h4>
          <span className="project-card-modal__message">
            Are you sure you want to delete this project? This action cannot be undone.
          </span>
        </div>
        <div className="project-card-modal__footer">
          <button
            className="project-card-modal__button project-card-modal__button--cancel"
            type="button"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="project-card-modal__button project-card-modal__button--delete"
            type="button"
            onClick={() => {
              onClick();
              onClose();
            }}
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};
