import { type CSSProperties } from "react";

type TSettings = {
  className?: string;
  style?: CSSProperties;
};

export const Settings = ({ className, style }: TSettings) => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M9.99935 13.0003C11.3801 13.0003 12.4993 11.8811 12.4993 10.5003C12.4993 9.11958 11.3801 8.00033 9.99935 8.00033C8.6186 8.00033 7.49935 9.11958 7.49935 10.5003C7.49935 11.8811 8.6186 13.0003 9.99935 13.0003Z"
      stroke="#727272"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.3513 9.16316L15.4366 6.95433L16.666 5.50033L14.9993 3.83366L13.5533 5.06945L11.2975 4.14178L10.7788 2.16699H9.15018L8.6236 4.16793L6.41969 5.09696L4.99935 3.83366L3.33268 5.50033L4.54382 6.99103L3.6431 9.20558L1.66602 9.66699V11.3337L3.66694 11.8799L4.59581 14.0834L3.33268 15.5003L4.99935 17.167L6.49198 15.9506L8.66352 16.8439L9.16602 18.8337H10.8327L11.3364 16.8447L13.5453 15.9299C13.9134 16.1931 14.9993 17.167 14.9993 17.167L16.666 15.5003L15.4293 14.0415L16.3443 11.832L18.3326 11.3147L18.3327 9.66699L16.3513 9.16316Z"
      stroke="#727272"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
