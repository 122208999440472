import React, { type SVGProps } from "react";

export const CatalogOpen = (props: SVGProps<SVGSVGElement>) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8.53196 5.8215L9.96772 7.25726H16.3066V14.4361H4.82052V5.8215H8.53196ZM9.1278 4.38574H4.82052C4.03086 4.38574 3.39194 5.03183 3.39194 5.8215L3.38477 14.4361C3.38477 15.2257 4.03086 15.8718 4.82052 15.8718H16.3066C17.0963 15.8718 17.7424 15.2257 17.7424 14.4361V7.25726C17.7424 6.46759 17.0963 5.8215 16.3066 5.8215H10.5636L9.1278 4.38574Z"
      fill="#71717A"
    />
  </svg>
);
