import { useState, type ChangeEvent } from "react";

import { TProjectSettingsInput } from "./ProjectSettingsInput.types";

import "./ProjectSettingsInput.css";

export const ProjectSettingsInput: TProjectSettingsInput = ({
  id,
  label,
  onChange,
  placeholder = "",
  value: initialValue = "",
  disabled = false,
  maxLength = 150,
}) => {
  const [value, setValue] = useState(initialValue);
  const [error, setError] = useState("");

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (maxLength && event.target.value.length >= maxLength) {
      setError(`Maximum length of ${maxLength} characters exceeded`);
      return;
    } else {
      setError("");
    }
    setValue(event.target.value);
    onChange && onChange(event);
  };

  return (
    <label htmlFor={id} className="project-settings__input-label">
      <span className="project-settings__label">{label}</span>
      <input
        id={id}
        className="project-settings__input"
        disabled={disabled}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
      />
      {error && <div className="project-settings__input-error">{error}</div>}
    </label>
  );
};
