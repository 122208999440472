import { useEffect, useState } from "react";
import { pagesInstance } from "./pages.instance.ts";
import type { Page } from "grapesjs";
import { pagesService } from "./pages.service.ts";
import { Insert } from "../../../../../assets/svg/Insert.tsx";
import { Actions } from "../../../../../assets/svg/Actions.tsx";

import useOutsideClick from "../../../../../hooks/useOutsideClick/useOutsideClick.ts";

import "./PagesMenu.css";

const PagesMenuElement = ({ page }: { page: Page }) => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useOutsideClick<HTMLDivElement>(() => setIsVisible(false));
  const handleClick = () => setIsVisible((prev) => !prev);

  return (
    <li
      key={page.getId()}
      className="pages-menu__pages-element"
      onClick={() => pagesInstance.send("SELECT_PAGE", { id: page.getId() })}
    >
      <span className="pages-menu__page-name">{page.getName()}</span>{" "}
      <button aria-label="show actions" onClick={handleClick}>
        <Actions />
      </button>
      <div className={`pages-menu__actions-modal${isVisible ? " visible" : ""}`} ref={ref}>
        <button className="actions-modal__button">Rename</button>
        <button className="actions-modal__button">Duplicate</button>
        <button
          className="actions-modal__button"
          onClick={() => {
            pagesInstance.send("DELETE_PAGE", { id: page.getId() });
          }}
        >
          Delete
        </button>
      </div>
    </li>
  );
};

export const PagesMenu = () => {
  const [pages, setPages] = useState<Page[]>([]);

  useEffect(() => {
    pagesInstance.start();
    pagesService.subscribe((st) => setPages(st));
    pagesInstance.send("LOAD_PAGES");

    return () => {
      pagesInstance.stop();
      pagesService.unsubscribe(setPages);
    };
  }, []);

  return (
    <section className="pages-menu">
      <header className="pages-menu__header">
        <h2 className="pages-menu__label">Pages</h2>
        <div className="pages-menu__cta-wrapper">
          <button onClick={() => pagesInstance.send("ADD_PAGE")} aria-label="add page">
            <Insert />{" "}
          </button>
        </div>
      </header>
      <ul className="pages-menu__pages-list">
        {pages.map((page) => {
          return <PagesMenuElement page={page} key={page.getId()} />;
        })}
      </ul>
    </section>
  );
};
