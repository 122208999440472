import { ButtonNew } from "../../../atoms/ButtonNew";

import { TPlan } from "./Plan.types";

import "./Plan.css";

export const Plan: TPlan = ({
  title,
  price,
  frequency,
  description,
  featuresTitle,
  features,
  renewalDate,
  actionButtonLabel,
  actionButtonOnClick,
  actionButtonHref,
  actionButtonDisabled,
  actionButtonLoading,
}) => {
  return (
    <article className="plan" data-priceplan-type={title.toLocaleLowerCase()}>
      <header className="plan__header">
        <h3 className="plan__title">{title}</h3>
        <div className="plan__price-container">
          <span className="plan__price">{price}</span>
          {frequency && <span className="plan__frequency">{frequency}</span>}
        </div>
      </header>
      <p className="plan__description">{description}</p>

      <section className="plan__features">
        <h4 className="plan__features-title">{featuresTitle}</h4>
        <ol className="plan__features-list">
          {features.map((feature) => (
            <li key={feature} className="plan__feature">
              {feature}
            </li>
          ))}
        </ol>
      </section>

      <footer className="plan__actions">
        {actionButtonDisabled && renewalDate ? (
          <p className="plan__renewal-info">Your plan renews on {renewalDate}</p>
        ) : (
          ""
        )}
        <ButtonNew
          className="plan__action-button"
          disabled={actionButtonDisabled}
          orange={actionButtonLabel === "Get started"}
          onClick={actionButtonOnClick}
          href={actionButtonHref}
          loading={actionButtonLoading}
        >
          {actionButtonLabel}
        </ButtonNew>
      </footer>
    </article>
  );
};
