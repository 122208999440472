import { useEffect, useState } from "react";
import { colorPickerServiceInstance } from "../ColorPicker/ColorPicker.service";

import "./ColorInput.css";

export const ColorInput = ({
  name,
  value,
  onChange,
}: {
  name: string;
  value: string;
  onChange: (name: string, value: string) => void;
}) => {
  const [localValue, setLocalValue] = useState<string>(value);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (value !== localValue) {
        onChange(name, localValue);
      }
    }, 200);
    return () => clearTimeout(timeoutId);
  }, [localValue]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalValue(e.target.value);
  };

  const handleColorChange = (color: string) => {
    setLocalValue(color);
  };

  const onClickHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    const position = (e.target as HTMLButtonElement).getBoundingClientRect();
    colorPickerServiceInstance.setupColorPicker(localValue, handleColorChange, { x: position.right, y: position.top });
    // colorPickerServiceInstance.setPosition({ x: position.left, y: position.top });
    // colorPickerServiceInstance.setColor(localValue);
    // colorPickerServiceInstance.setCallback(handleColorChange);
  };

  if (value.includes("linear-gradient") || value.includes("radial-gradient"))
    return (
      <span className="rootStyle__variable-value">
        <div className="rootStyle__color-value" style={{ background: localValue }}></div>
        <span className="rootStyle__color-name">{localValue}</span>
      </span>
    );

  return (
    <span className="rootStyle__variable-value">
      <button style={{ background: localValue }} onClick={(e) => onClickHandler(e)} aria-label="pick color"></button>
      <input type="text" name={name} value={localValue} onChange={handleChange} />
    </span>
  );
};
