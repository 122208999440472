import { Seo } from "../../atoms/Seo";
import { Navigation } from "../../Dashboard/organisms/Navigation";
import { WeirdIconWrapper } from "../../atoms/WeirdIconWrapper";

import { AppTourIcon } from "../../../../assets/svg/app-tour-icon";
import { DiscordIcon } from "../../../../assets/svg/discord-icon";
import { XIcon } from "../../../../assets/svg/x-icon";
import { YoutubeIcon } from "../../../../assets/svg/youtube-icon";

import CommunityAvatar1 from "../../../../assets/png/community-avatar-1.png";
import CommunityAvatar2 from "../../../../assets/png/community-avatar-2.png";
import CommunityAvatar3 from "../../../../assets/png/community-avatar-3.png";
import CommunityAvatar4 from "../../../../assets/png/community-avatar-4.png";

import { type TCommunity, type CommunityData } from "./Community.types";

import "./Community.css";

const community: CommunityData[] = [
  {
    icon: <DiscordIcon />,
    label: "Discord",
    onClick: () => window.open("https://discord.com/invite/XHwhKVAQM9", "__blank", "noopener noreferrer"),
  },
  {
    icon: <XIcon />,
    label: "Twitter/X",
    onClick: () => window.open("https://twitter.com/Codejetai", "__blank", "noopener noreferrer"),
  },
  {
    icon: <YoutubeIcon />,
    label: "Youtube",
    onClick: () => window.open("https://www.youtube.com/@codejetai", "__blank", "noopener noreferrer"),
  },
];

const avatarGroup = [CommunityAvatar1, CommunityAvatar2, CommunityAvatar3, CommunityAvatar4];

export const Community: TCommunity = () => {
  return (
    <>
      <Seo title="Community - Codejet" />
      <Navigation />
      <div className="community__content container">
        <div className="community__content-wrapper">
          <div className="community__header">
            <WeirdIconWrapper>
              <AppTourIcon />
            </WeirdIconWrapper>
            <div className="community__typography">
              <h1 className="community__title">Codejet community</h1>
              <h3 className="community__subtitle">
                Join our community of likeminded designers and developers, gain tips and share your ideas
              </h3>
            </div>
          </div>
          <div className="community__icons">
            {community.map((item) => {
              return (
                <button type="button" key={item.label} className="community__button" onClick={item.onClick}>
                  {item.icon}
                  {item.label}
                </button>
              );
            })}
          </div>
          <div className="community__footer">
            <div className="community__avatar-group">
              {avatarGroup.map((avatar, index) => {
                return (
                  <img key={avatar} src={avatar} alt={`Community avatar ${index}`} className="community__avatar" />
                );
              })}
            </div>
            <p className="community__footer-text">100+ members</p>
          </div>
        </div>
      </div>
    </>
  );
};
