import { useEffect, useState } from "react";
import { Trait } from "grapesjs";

export const Select = ({ trait }: { trait: Trait }) => {
  const [value, setValue] = useState(trait.getValue());

  useEffect(() => {
    if (value === "false") {
      trait.setValue(false);
    } else {
      trait.setValue(value);
    }
  }, [value]);

  return (
    <li key={trait.cid} className="trait-manager__trait">
      <label>{trait.getLabel()}</label>
      <select onChange={(e) => setValue(e.target.value)} value={value}>
        {trait.attributes.options?.map((option: { value: string }) => {
          return (
            <option key={option.value} value={option.value}>
              {option.value}
            </option>
          );
        })}
      </select>
    </li>
  );
};
