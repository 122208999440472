import { type CSSProperties } from "react";

type TUndo = {
  className?: string;
  style?: CSSProperties;
};

export const Undo = ({ className, style }: TUndo) => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M3.75 7.16699C7.08333 7.16699 9.16667 7.16699 12.5 7.16699C12.5 7.16699 16.6667 7.16699 16.6667 11.0886C16.6667 15.5003 12.5 15.5003 12.5 15.5003C9.64283 15.5003 8.09524 15.5003 5.23809 15.5003"
      stroke="#727272"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.25065 10.0833C5.11163 8.94433 4.47301 8.30569 3.33398 7.16667C4.47301 6.02764 5.11163 5.38902 6.25065 4.25"
      stroke="#727272"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
