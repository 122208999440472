import { type CSSProperties } from "react";

type TLayers = {
  className?: string;
  style?: CSSProperties;
};

export const Layers = ({ className, style }: TLayers) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <mask id="mask0_4_7766" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
      <rect width="24" height="24" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_4_7766)">
      <path
        d="M12.0001 19.8959L3.80786 13.5306L5.03486 12.5884L12.0001 17.9806L18.9654 12.5884L20.1924 13.5306L12.0001 19.8959Z"
        fill="#71717A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9999 15.4617L3.80762 9.09646L11.9999 2.73096L20.1921 9.09646L11.9999 15.4617ZM12 13.5465L17.75 9.09645L12 4.64645L6.25 9.09645L12 13.5465Z"
        fill="#71717A"
      />
    </g>
  </svg>
);
