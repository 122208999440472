import { type CSSProperties } from "react";

type TCheck = {
  className?: string;
  style?: CSSProperties;
};

export const Check = ({ className, style }: TCheck) => (
  <svg
    width="16"
    height="11"
    viewBox="0 0 16 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M14.6654 1L5.4987 10.1667L1.33203 6"
      stroke="#85FF0A"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
