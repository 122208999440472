import { type CSSProperties } from "react";

type TRedo = {
  className?: string;
  style?: CSSProperties;
};

export const Redo = ({ className, style }: TRedo) => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M16.2507 7.16699C12.9173 7.16699 10.834 7.16699 7.50065 7.16699C7.50065 7.16699 3.33398 7.16699 3.33398 11.0886C3.33398 15.5003 7.50065 15.5003 7.50065 15.5003C10.3578 15.5003 11.9054 15.5003 14.7626 15.5003"
      stroke="#727272"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.75 10.0833C14.889 8.94433 15.5277 8.30569 16.6667 7.16667C15.5277 6.02764 14.889 5.38902 13.75 4.25"
      stroke="#727272"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
