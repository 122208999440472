import { type CSSProperties } from "react";

type TLogo = {
  className?: string;
  style?: CSSProperties;
};

export const Logo = ({ className, style }: TLogo) => (
  <svg
    width="61"
    height="29"
    viewBox="0 0 61 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M5.12175 21.5044C1.96691 21.5044 0 19.2091 0 16.2329C0 13.2568 1.94743 10.9614 4.98543 10.9614C7.57551 10.9614 9.17241 12.4592 9.52295 14.6184H6.93286C6.75759 13.7431 6.07599 13.0817 5.10227 13.0817C3.50538 13.0817 2.68746 14.3072 2.68746 16.2329C2.68746 18.1197 3.44696 19.3841 5.06333 19.3841C6.13441 19.3841 6.89391 18.82 7.06918 17.6918H9.62032C9.44505 19.8121 7.78973 21.5044 5.12175 21.5044Z"
      fill="#A1A1AA"
    />
    <path
      d="M14.5275 21.5044C11.6761 21.5044 9.55343 19.2091 9.55343 16.2329C9.55343 13.2568 11.6761 10.9614 14.5275 10.9614C17.6239 10.9614 19.5015 13.2568 19.5015 16.2329C19.5015 19.2091 17.6239 21.5044 14.5275 21.5044ZM14.5275 19.4814C16.1438 19.4814 16.8141 18.1781 16.8141 16.2329C16.8141 14.2877 16.1438 12.965 14.5275 12.965C13.1367 12.965 12.2409 14.2877 12.2409 16.2329C12.2409 18.1781 13.1367 19.4814 14.5275 19.4814Z"
      fill="#A1A1AA"
    />
    <path
      d="M23.9816 21.5044C21.4694 21.5044 19.7556 19.4619 19.7556 16.2329C19.7556 13.1206 21.5278 10.9614 24.0205 10.9614C25.4227 10.9614 26.299 11.6033 26.8443 12.4398H26.9027V7.30445H29.5512V21.2126H27.0001V19.9093H26.9611C26.3769 20.8625 25.3837 21.5044 23.9816 21.5044ZM24.5658 19.2869C26.1432 19.2869 26.9611 18.1197 26.9611 16.2913C26.9611 14.385 26.338 13.1011 24.6437 13.1011C23.1831 13.1011 22.4431 14.385 22.4431 16.2329C22.4431 18.1587 23.2026 19.2869 24.5658 19.2869Z"
      fill="#A1A1AA"
    />
    <path
      d="M35.2309 21.5044C31.9203 21.5044 30.0508 19.1896 30.0508 16.2135C30.0508 13.2568 32.0566 10.9614 34.9778 10.9614C38.191 10.9614 39.9048 13.4124 39.9048 16.9721H32.6603C32.8551 18.5088 33.7119 19.4619 35.2115 19.4619C36.2436 19.4619 36.8473 18.9951 37.1394 18.2365H39.749C39.379 20.0066 37.8015 21.5044 35.2309 21.5044ZM35.0167 13.0039C33.6146 13.0039 32.933 13.8403 32.6993 15.1436H37.1394C37.0615 13.8792 36.2241 13.0039 35.0167 13.0039Z"
      fill="#A1A1AA"
    />
    <path
      d="M40.2395 24.5C39.7527 24.5 39.149 24.4611 38.74 24.3833V22.4964C39.8111 22.477 40.3953 22.3797 40.3953 21.4655V11.2337H43.0438V21.6017C43.0438 22.7688 42.8102 23.3523 42.3817 23.7803C41.8754 24.3055 41.0964 24.5 40.2395 24.5ZM40.3953 9.67759V7.30445H43.0438V9.67759H40.3953Z"
      fill="#A1A1AA"
    />
    <path
      d="M49.4438 21.5044C46.1331 21.5044 44.2636 19.1896 44.2636 16.2135C44.2636 13.2568 46.2694 10.9614 49.1906 10.9614C52.4039 10.9614 54.1176 13.4124 54.1176 16.9721H46.8731C47.0679 18.5088 47.9248 19.4619 49.4243 19.4619C50.4564 19.4619 51.0601 18.9951 51.3522 18.2365H53.9618C53.5918 20.0066 52.0144 21.5044 49.4438 21.5044ZM49.2295 13.0039C47.8274 13.0039 47.1458 13.8403 46.9121 15.1436H51.3522C51.2743 13.8792 50.4369 13.0039 49.2295 13.0039Z"
      fill="#A1A1AA"
    />
    <path
      d="M54.569 12.9844V11.2337H55.9517V8.12143H58.5418V11.2337H60.2555V12.9844H58.5418V18.2754C58.5418 19.0145 58.9507 19.2674 59.535 19.2674C59.866 19.2674 60.3139 19.248 60.3139 19.248V21.1932C60.3139 21.1932 59.7102 21.2321 58.6586 21.2321C57.3733 21.2321 55.9517 20.7263 55.9517 18.8395V12.9844H54.569Z"
      fill="#A1A1AA"
    />
    <path
      d="M45.5436 7.93506L43.0437 5.74887V3.17947H40.3957V5.74887L37.8975 7.93506V4.09779L41.7197 0.5L45.5436 4.09779V7.93506Z"
      fill="#FF4900"
    />
  </svg>
);
