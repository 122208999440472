import { EditorConfig } from "grapesjs";
import { projectsService } from "../../../machines/projects/projects.instance.ts";
import plugin from "grapesjs-blocks-basic";

export const editorConfig: EditorConfig = {
  container: "#gjs",
  height: "100%",
  width: "100%",
  storageManager: {
    type: "remote",
    autosave: true,
    stepsBeforeSave: 1,
  },
  forceClass: false, // setting it make grape js do not remove inline style from path in svg
  undoManager: { trackSelection: false },
  selectorManager: {
    componentFirst: true,
    // appendTo: "#gjs-selector-manager",
    // custom: true
  },
  panels: {
    defaults: [
      {
        id: "panel-top",
        el: ".topbar_alpha",
        buttons: [],
      },
    ],
  },
  plugins: [
    plugin,
    // grapesjs.plugins.get("web-preset"),
    (editor) => {
      // set basic rules for empty blocks
      editor.Css.setRule('[data-gjs-type^="cj"]:empty', {
        "background-color": "#D4D4D8",
        height: "50px",
        width: "100%",
        padding: "5px",
      });
      editor.Css.setRule(".gjs-selected", {
        outline: "2px solid #CC3202!important",
        "outline-offset": "-2px",
      });
      editor.Css.setRule('[data-gjs-type^="cj"]:empty::before', {
        content: '"Add content"',
        "background-color": "#FFB26D",
        color: "#000",
        "font-size": "16px",
        "font-weight": "bold",
        "font-family":
          '-apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Open Sans", Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif',
        height: "100%",
        display: "flex",
        "align-items": "center",
        "justify-content": "center",
        "min-height": "30px",
        padding: "0 10px",
        opacity: "0.3",
        "border-radius": "3px",
        "white-space": "nowrap",
        overflow: "hidden",
        "text-overflow": "ellipsis",
      });
      editor.Css.setRule(".iframe-no-pointer", {
        "pointer-events": "none",
      });
    },
  ],
  layerManager: {
    // appendTo: "#gjs-layer-manager",
    custom: true,
  },
  blockManager: {
    custom: true,
    blocks: [],
  },
  projectData: {},
  pageManager: {
    appendTo: "#gjs-page-manage",
    pages: [
      {
        id: "my-first-page",
        name: "Page 1",
        styles: ".my-page1-el { color: red }",
        component: '<div class="my-page1-el">Page 1</div>',
      },
    ],
  },
  assetManager: {
    custom: {
      //TODO check why custom: true is not working
      //maybe this is because of the version of grapesjs
      open() {
        console.log("Open Asset Manager");
      },
      close() {
        console.log("Close Asset Manager");
      },
    },
    assets: [],
  },
  modal: { custom: true },
  styleManager: {
    appendTo: "#styles-container",
  },
  traitManager: {
    custom: true,
  },
  deviceManager: {
    devices: [
      {
        name: "Desktop",
        width: "",
      },
      {
        name: "Tablet",
        width: "768px",
        widthMedia: "992px",
      },
      {
        name: "Mobile",
        width: "576px",
        widthMedia: "768px",
      },
    ],
  },
};
