import { useEffect, useState } from "react";
import { Trait } from "grapesjs";
import { convertToEmbeddedLink } from "../helpers/convertToEmbeddedLink";

export const Text = ({ trait }: { trait: Trait }) => {
  const [value, setValue] = useState(trait.getValue());
  const [error, setError] = useState("");

  const isCodejetVideoSrcTrait = trait.target?.get("type") === "codejetVideo" && trait.attributes?.name === "src";

  useEffect(() => {
    trait.setValue(value);
  }, [value]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError("");
    if (isCodejetVideoSrcTrait) {
      try {
        const embedUrl = convertToEmbeddedLink(e.target.value);
        setValue(embedUrl);
      } catch (error) {
        setValue(e.target.value);
        setError("Invalid URL");
      }
    } else {
      setValue(e.target.value);
    }
  };

  return (
    <li key={trait.cid} className={`trait-manager__trait${error ? " trait-manager__trait--error" : ""}`}>
      <label>{trait.getLabel()}</label>
      <input className="" onChange={handleChange} value={value} />
      {error && <span className="trait-manager__error-message">{error}</span>}
    </li>
  );
};
