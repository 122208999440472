import { type CSSProperties } from "react";

type TCode = {
  className?: string;
  style?: CSSProperties;
};

export const Code = ({ className, style }: TCode) => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M11.2507 5.5L8.33398 15.9167"
      stroke="#D4D4D8"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.41667 7.58301L2.5 10.4997L5.41667 13.4163"
      stroke="#FF4900"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.584 7.58301L17.5007 10.4997L14.584 13.4163"
      stroke="#FF4900"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
