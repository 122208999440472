import { type CSSProperties } from "react";

type TFaceID = {
  className?: string;
  style?: CSSProperties;
};

export const FaceID = ({ className, style }: TFaceID) => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M6.66732 3.83301H5.00065C4.08018 3.83301 3.33398 4.5792 3.33398 5.49967V7.16634M13.334 3.83301H15.0007C15.9212 3.83301 16.6673 4.5792 16.6673 5.49967V7.16634M6.66732 17.1663H5.00065C4.08018 17.1663 3.33398 16.4202 3.33398 15.4997V13.833M13.334 17.1663H15.0007C15.9212 17.1663 16.6673 16.4202 16.6673 15.4997V13.833"
      stroke="#727272"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
