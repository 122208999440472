import { useState, useEffect } from "react";
import dayjs from "dayjs";

import { Seo } from "../../atoms/Seo";
import { ButtonNew } from "../../atoms/ButtonNew";

import { Switch } from "../../Dashboard/atoms/Switch";
import { Plan } from "../../Dashboard/molecules/Plan";
import { Navigation } from "../../Dashboard/organisms/Navigation";

import { UserService } from "../../../services/UserService/UserService";
import { PaymentsService } from "../../../services/Payments/Payments";
import { useAsyncAction } from "../../../utils/useAsyncAction/useAsyncAction";
import { formatPeriodDate } from "../../../utils/formatPeriodDate/formatPeriodDate";

import type { TPricing } from "./Pricing.types";

import "./Pricing.css";

const userService = UserService.getInstance();

const switchInitialValue = (userService: UserService) => {
  if (userService.periodType === "month") {
    return false;
  }
  if (userService.periodType === "annual") {
    return true;
  }
};

export const Pricing: TPricing = () => {
  const priceID = import.meta.env.VITE_SUBSCRIPTION_PRICE_ID;
  const currentTimestamp = dayjs().unix();
  const currentPeriodEnd = userService.data?.CURRENT_PERIOD_END || 0;
  const isActiveSubscription = currentTimestamp < currentPeriodEnd;
  const isActiveFlag = userService.isActiveSubscriptionFlag();

  const [_, setState] = useState(userService.state);
  const [isYearly, setIsYearly] = useState(switchInitialValue(userService));
  const { isLoading, handleAsyncAction } = useAsyncAction();

  const canPurchase = () => {
    const isCancelledSubscription = userService.isCanceledSubscription();
    const isUndefinedSubscription = userService.isUndefinedSubscription();
    const isActiveLifeTime = userService.isActiveLifeTime();

    return (isCancelledSubscription || isUndefinedSubscription || !isActiveLifeTime) && !isActiveSubscription;
  };

  useEffect(() => {
    userService.subscribe(setState);
    return () => userService.unsubscribe(setState);
  }, []);

  const planData = {
    monthly: [
      {
        title: "Free",
        price: "$0",
        frequency: "/mo",
        description: "Everything you need to get started with Codejet. Build, share and publish your projects.",
        featuresTitle: "Free includes:",
        features: [
          "Access to Codejet Design System",
          "1 Project",
          "Publish to codejet.io domain",
          "Unlimited imports from Figma",
          "Code generation in HTML",
          "Edit the project in visual editor",
        ],
        actionButtonLabel: canPurchase() ? "Current plan" : "Downgrade to Free",
        actionButtonOnClick: canPurchase()
          ? undefined
          : async () => await handleAsyncAction(PaymentsService.createPortalSession),
        actionButtonDisabled: canPurchase() || isLoading,
        actionButtonLoading: isLoading,
      },
      {
        title: "Professional",
        price: "$39",
        frequency: "/mo",
        description:
          "For small teams and freelancers that want to collaborate on projects hosted on custom domains & converted to React.js code",
        featuresTitle: "Everything in Starter, plus:",
        features: ["5 custom domains", "Unlimited projects"],
        renewalDate: formatPeriodDate(userService.data?.CURRENT_PERIOD_END || 0),
        actionButtonLabel: canPurchase() ? "Get started" : "Current plan",
        actionButtonOnClick: isActiveFlag
          ? async () => await PaymentsService.createPortalSession()
          : canPurchase()
          ? async () => await PaymentsService.createCheckoutSession(priceID, "subscription")
          : undefined,
        actionButtonDisabled: !canPurchase(),
      },
      {
        title: "Teams",
        price: "Custom",
        description: "More scale, control, & support for teams with multiple designers & developers.",
        featuresTitle: "Everything in Professional, plus:",
        features: [
          "Custom design system",
          "Customized code generation",
          "Reverse proxy publishing",
          "Embed & white-labeling",
          "Custom integrations",
          "Assigned CSM and Customer Engineer",
        ],
        actionButtonLabel: "Get started",
        actionButtonHref:
          "mailto:sales@codejetai.co?subject=Inquiry%20About%20the%20Custom%20Team%20Plan&body=Hello%2C%0A%0AI%20would%20like%20to%20learn%20more%20about%20the%20Custom%20plan%20for%20teams%20that%20offers%20advanced%20features%20such%20as%20a%20custom%20design%20system%20and%20integrations.%20We%20are%20interested%20in%20code%20generation%20customization%20options%20and%20reverse%20proxy%20publishing%2C%20as%20well%20as%20embedding%20and%20branding%20possibilities.%20Could%20you%20provide%20more%20information%20about%20these%20features%20and%20the%20technical%20support%20available%20within%20this%20plan%3F%0A%0ABest%20regards%2C%0A%5BYour%20Name%5D",
      },
    ],
    yearly: [
      {
        title: "Free",
        price: "$0",
        frequency: "/mo",
        description: "Everything you need to get started with Codejet. Build, share and publish your projects.",
        featuresTitle: "Free includes:",
        features: [
          "Access to Codejet Design System",
          "1 Project",
          "Publish to codejet.io domain",
          "Unlimited imports from Figma",
          "Code generation in HTML",
          "Edit the project in visual editor",
        ],
        actionButtonLabel: canPurchase() ? "Current plan" : "Downgrade to Free",
        actionButtonOnClick: canPurchase()
          ? undefined
          : async () => handleAsyncAction(PaymentsService.createPortalSession),
        actionButtonDisabled: canPurchase() || isLoading,
        actionButtonLoading: isLoading,
      },
      {
        title: "Professional",
        price: "$30",
        frequency: "/mo",
        description:
          "For small teams and freelancers that want to collaborate on projects hosted on custom domains & converted to React.js code",
        featuresTitle: "Everything in Starter, plus:",
        features: ["5 custom domains", "Unlimited projects"],
        renewalDate: formatPeriodDate(userService.data?.CURRENT_PERIOD_END || 0),
        actionButtonLabel: canPurchase() ? "Get started" : "Current plan",
        actionButtonOnClick: isActiveFlag
          ? async () => await PaymentsService.createPortalSession()
          : canPurchase()
          ? async () => await PaymentsService.createCheckoutSession(priceID, "subscription")
          : undefined,
        actionButtonDisabled: !canPurchase(),
      },
      {
        title: "Teams",
        price: "Custom",
        description: "More scale, control, & support for teams with multiple designers & developers.",
        featuresTitle: "Everything in Professional, plus:",
        features: [
          "Custom design system",
          "Customized code generation",
          "Reverse proxy publishing",
          "Embed & white-labeling",
          "Custom integrations",
          "Assigned CSM and Customer Engineer",
        ],
        actionButtonLabel: "Get started",
        actionButtonHref:
          "mailto:sales@codejetai.co?subject=Inquiry%20About%20the%20Custom%20Team%20Plan&body=Hello%2C%0A%0AI%20would%20like%20to%20learn%20more%20about%20the%20Custom%20plan%20for%20teams%20that%20offers%20advanced%20features%20such%20as%20a%20custom%20design%20system%20and%20integrations.%20We%20are%20interested%20in%20code%20generation%20customization%20options%20and%20reverse%20proxy%20publishing%2C%20as%20well%20as%20embedding%20and%20branding%20possibilities.%20Could%20you%20provide%20more%20information%20about%20these%20features%20and%20the%20technical%20support%20available%20within%20this%20plan%3F%0A%0ABest%20regards%2C%0A%5BYour%20Name%5D",
      },
    ],
  };

  return (
    <>
      <Seo title="Pricing - Codejet" />
      <Navigation />
      <div className="pricing__content container">
        <div className="pricing__heading">
          <div className="pricing__typography">
            <h1 className="pricing__title">Pricing plans</h1>
            <h2 className="pricing__subtitle">
              Find the right plan for teams of all sizes{" "}
              {isActiveSubscription && (
                <ButtonNew
                  orange
                  type="text"
                  disabled={isLoading}
                  loading={isLoading}
                  onClick={async () => handleAsyncAction(PaymentsService.createPortalSession)}
                >
                  Edit payment data
                </ButtonNew>
              )}
            </h2>
          </div>
          <Switch
            id="subscriptionSwitch"
            onChange={setIsYearly}
            ariaLabelledby="subscriptionLabel"
            defaultChecked={switchInitialValue(userService)}
          />
        </div>
        <div className="pricing__plans">
          {planData[isYearly ? "yearly" : "monthly"].map((plan) => (
            <Plan key={plan.title} {...plan} />
          ))}
        </div>
      </div>
    </>
  );
};
