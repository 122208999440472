import prettier from "prettier/standalone";
import htmlParser from "prettier/parser-html";
import cssParser from "prettier/parser-postcss";
import babelParser from "prettier/parser-babel";

export const formatCode = (code: string, lang = "html") => {
  const parser = lang === "html" ? "html" : lang;
  return prettier.format(code, {
    parser,
    plugins: [htmlParser, cssParser],
    htmlWhitespaceSensitivity: "ignore",
  });
};
