import { useEffect, useState } from "react";

import { ImportModal as ImportModalService } from "./ImportModal.service";
import useOutsideClick from "../../../../hooks/useOutsideClick/useOutsideClick";

import { ButtonNew } from "../../../atoms/ButtonNew";
import { IconButton } from "../../../atoms/IconButton";

import { Close } from "../../../../../assets/svg/close";
import { ExternalLink } from "../../../../../assets/svg/external-link";
import tempImage from "../../../../../assets/png/temp.png";

import { TImportModal } from "./ImportModal.types";

import "./ImportModal.css";

const importModal = ImportModalService.getInstance();

export const ImportModal: TImportModal = () => {
  const [_, setState] = useState(false);
  useEffect(() => {
    importModal.subscribe(setState);
    return () => importModal.unsubscribe(setState);
  }, []);
  const isOpen = importModal.state;
  const closeModal = () => importModal.closeModal();
  const ref = useOutsideClick<HTMLDivElement>(closeModal);

  return (
    <div className="import-modal__wrapper">
      <div className={"import-modal__backdrop" + (isOpen ? " import-modal__backdrop--visible" : "")}>
        <div className={`import-modal ${isOpen && "import-modal--visible"}`} ref={ref}>
          <div className="import-modal__header">
            <span className="import-modal__typography import-modal__typography--bold">Import design from Figma</span>
            <IconButton
              className="import-modal__btn-icon"
              ariaLabel="Close 'Import design from Figma' modal"
              onClick={() => closeModal()}
            >
              <Close />
            </IconButton>
          </div>
          <img src={tempImage} alt={"figma "} />
          <ol className="import-modal__steps import-modal__typography import-modal__typography--regular">
            <li>
              Open the{" "}
              <ButtonNew
                className="import-modal__btn-link"
                orange
                type="text"
                iconEnd={<ExternalLink />}
                href="https://www.figma.com/community/file/1364964277652829877/jet-ui-design-system-by-codejet-ai"
              >
                Jet UI Design System
              </ButtonNew>{" "}
              in Figma and use it to design your project
            </li>
            <li>
              Install and run{" "}
              <ButtonNew
                className="import-modal__btn-link"
                orange
                type="text"
                iconEnd={<ExternalLink />}
                href="https://www.figma.com/community/plugin/1276157706852546687/codejet-assistant?searchSessionId=lv0jfu4e-szr7osmn229"
              >
                Codejet Plugin
              </ButtonNew>
            </li>
            <li>Select designs to import</li>
            <li>{'Click "Generate Code"'}</li>
            <li>Edit in visual editor</li>
          </ol>
          <ButtonNew
            className="import-modal__btn"
            orange
            href="https://www.figma.com/community/plugin/1276157706852546687/codejet-assistant?searchSessionId=lv0jfu4e-szr7osmn229"
          >
            Get the plugin
          </ButtonNew>
        </div>
      </div>
    </div>
  );
};
