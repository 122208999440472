import React, { type SVGProps } from "react";

export const FileClose = (props: SVGProps<SVGSVGElement>) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M11.4366 2.82129H5.69357C4.9039 2.82129 4.26499 3.46738 4.26499 4.25705L4.25781 15.7431C4.25781 16.5328 4.89672 17.1789 5.68639 17.1789H14.3081C15.0978 17.1789 15.7439 16.5328 15.7439 15.7431V7.12856L11.4366 2.82129ZM5.69357 15.7431V4.25705H10.7187V7.84644H14.3081V15.7431H5.69357Z"
      fill="#71717A"
    />
  </svg>
);
