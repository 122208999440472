import { useState } from "react";

import { Chip } from "../Chip";

import type { TSwitch } from "./Switch.types";

import "./Switch.css";

export const Switch: TSwitch = ({ id, onChange, ariaLabelledby, defaultChecked = false }) => {
  const [isChecked, setIsChecked] = useState(defaultChecked);

  const toggleSwitch = () => {
    setIsChecked(!isChecked);
    onChange(!isChecked);
  };

  return (
    <div className="switch">
      <label htmlFor={id} className={`switch__label switch__label--left${!isChecked ? " switch__label--active" : ""}`}>
        Monthly
      </label>
      <div
        role="switch"
        id={id}
        aria-checked={isChecked}
        tabIndex={0}
        onClick={toggleSwitch}
        onKeyDown={(event) => {
          if (event.key === "Enter" || event.key === " ") {
            toggleSwitch();
          }
        }}
        className={`switch__toggle${isChecked ? " switch__toggle--active" : ""}`}
        aria-labelledby={ariaLabelledby}
      >
        <div className={`switch__knob${isChecked ? " switch__knob--active" : ""}`} />
      </div>
      <label htmlFor={id} className={`switch__label switch__label--right${isChecked ? " switch__label--active" : ""}`}>
        Yearly
      </label>
      <Chip label="2 months free" checked={isChecked} className="switch__badge" />
    </div>
  );
};
