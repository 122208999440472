import { Subscriber } from "../../../../../services/abstract/Subscriber/Subscriber.ts";
import { Asset, ComponentDefinition } from "grapesjs";
import { fetchAssetsByProjectId } from "./AssetsRepository.ts";
import { editorInstance, editorService } from "../editor";
import { projectsService } from "../../../../../machines/projects/projects.instance.ts";

// props.open (boolean) - Indicates if the Asset Manager is open
// props.assets (Array<Asset>) - Array of all assets
// props.types (Array<String>) - Array of asset types requested, eg. ['image'],
// props.close (Function) - A callback to close the Asset Manager
// props.remove (Function<Asset>) - A callback to remove an asset
// props.select (Function<Asset, boolean>) - A callback to select an asset
// props.container (HTMLElement) - The element where you should append your UI

export type AssetManagerApi = {
  open: boolean;
  assets: Asset[];
  types: string[];
  close: () => void;
  remove: (asset: Asset) => void;
  select: (asset: Asset, multiple: boolean) => void;
  container: HTMLElement;
  add(assets: { type?: string; src: string }[]): void;
};

export type AssetManagerState = {
  open: boolean;
  assets: Asset[];
};

export class AssetManagerService extends Subscriber<AssetManagerState> {
  state: AssetManagerState = {
    open: false,
    assets: [],
  };

  assetManager?: AssetManagerApi;
  component?: ComponentDefinition;

  selectComponent(component: ComponentDefinition) {
    this.component = component;
  }
  deselectComponent() {
    this.component = undefined;
  }

  setAssetManager(assetManager: AssetManagerApi) {
    this.assetManager = assetManager;
  }

  close() {
    this.state.open = false;
    this.assetManager?.close();
  }

  remove(asset: Asset) {
    this.assetManager?.remove(asset);
  }

  select(asset: Asset, multiple: boolean) {
    this.assetManager?.select(asset, multiple);
    if (this.component) {
      //@ts-ignore
      this.component.set("src", asset.get("src"));
    }
  }

  add(asset: Asset) {
    this.state.assets.push(asset);
  }

  async loadAssets() {
    const editor = editorService.getEditor();

    const projectId = projectsService.getActiveProject()?.projectId;

    if (!projectId) return;

    const assets = await fetchAssetsByProjectId(projectId.replace("/", "%2F"));

    if (editor) {
      assets.forEach((asset) => editor.AssetManager.add(asset));
    }

    this.state.assets = editor?.AssetManager.getAll() || [];

    return Promise.resolve();
  }

  clearAllAssets() {
    this.state.assets = [];

    const editor = editorService.getEditor();
    if (editor) {
      editor.AssetManager.getAll().forEach((asset: Asset) => editor.AssetManager.remove(asset));
    }
    console.log("clearAllAssets");
  }

  open() {
    this.state.open = true;
  }
}
