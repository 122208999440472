import { useState } from "react";
import { toast } from "react-toastify";

export const useAsyncAction = () => {
  const [isLoading, setIsLoading] = useState(false);

  const handleAsyncAction = async (action: () => Promise<void>) => {
    setIsLoading(true);
    try {
      await action();
    } catch (error) {
      const typedError = error as {
        response: { data: { message: string; statusCode: number } };
      };
      const message = typedError?.response?.data?.message || "Something went wrong";
      console.log({ message });
      // if (error?.response?.statusCode !== 401) {
      //   toast.error(message);
      // }
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, handleAsyncAction };
};
