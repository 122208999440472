import { type CSSProperties } from "react";

type TAssets = {
  className?: string;
  style?: CSSProperties;
};

export const Assets = ({ className, style }: TAssets) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <mask id="mask0_4_7780" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
      <rect width="24" height="24" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_4_7780)">
      <path
        d="M3.30775 20.3845C2.80908 20.3845 2.38308 20.2079 2.02975 19.8547C1.67658 19.5016 1.5 19.0757 1.5 18.577V6.5385H3V18.577C3 18.6667 3.02883 18.7403 3.0865 18.798C3.14417 18.8557 3.21792 18.8845 3.30775 18.8845H19.5V20.3845H3.30775ZM6.80775 16.8845C6.30892 16.8845 5.88292 16.7079 5.52975 16.3548C5.17658 16.0016 5 15.5757 5 15.077V4.30775C5 3.80908 5.17658 3.38308 5.52975 3.02975C5.88292 2.67658 6.30892 2.5 6.80775 2.5H11.7885L13.7885 4.5H20.6923C21.1909 4.5 21.6169 4.67658 21.9703 5.02975C22.3234 5.38308 22.5 5.80908 22.5 6.30775V15.077C22.5 15.5757 22.3234 16.0016 21.9703 16.3548C21.6169 16.7079 21.1909 16.8845 20.6923 16.8845H6.80775ZM6.80775 15.3848H20.6923C20.7821 15.3848 20.8558 15.3558 20.9135 15.298C20.9712 15.2403 21 15.1667 21 15.077V6.30775C21 6.21792 20.9712 6.14417 20.9135 6.0865C20.8558 6.02883 20.7821 6 20.6923 6H13.175L11.175 4H6.80775C6.71792 4 6.64417 4.02883 6.5865 4.0865C6.52883 4.14417 6.5 4.21792 6.5 4.30775V15.077C6.5 15.1667 6.52883 15.2403 6.5865 15.298C6.64417 15.3558 6.71792 15.3848 6.80775 15.3848Z"
        fill="#727272"
      />
      <path
        d="M18.5772 12.9999H9.23096L11.5272 10.0191L13.0965 11.9614L15.4732 8.92285L18.5772 12.9999Z"
        fill="#727272"
      />
    </g>
  </svg>
);
