import { projectsInstance } from "../../../../machines/projects/projects.instance";

import { NoContent } from "./NoContent";
import { ProjectCard } from "../../atoms/ProjectCard";

import type { Project } from "../../../../types/types";

export const RenderContent = ({
  isSkeleton,
  isNoContent,
  projects,
  isPlanActive,
}: {
  isSkeleton: boolean;
  isNoContent: boolean;
  isPlanActive: () => boolean;
  projects: Project[];
}) => {
  if (isSkeleton) {
    return (
      <>
        {Array.from({ length: 3 }).map((_, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <ProjectCard key={index} skeleton style={{ animationDelay: `calc(${index} * 250ms)` }} />
        ))}
      </>
    );
  } else if (isNoContent) {
    return <NoContent />;
  } else {
    return (
      <>
        {projects.map((project, idx) => (
          <ProjectCard
            key={project.projectId}
            name={project.name}
            description={project.description}
            timestamp={project.timestamp}
            thumbnail={project.thumbnail}
            isLocked={() => (idx === 0 ? false : isPlanActive())}
            isPublished={project?.subdomain?.published || project?.customDomain?.published}
            onClick={() => projectsInstance.send("SELECT_PROJECT", { projectId: project.projectId })}
            onEdit={() => projectsInstance.send("PROJECT_SETTINGS", { projectId: project.projectId })}
            onDelete={async () => await projectsInstance.getSnapshot().context.service.deleteProject(project.projectId)}
            style={{ animationDelay: `calc(${idx} * 250ms)`, zIndex: `${996 - idx}` }}
          />
        ))}
      </>
    );
  }
};
