import { TChip } from "./Chip.types";

import "./Chip.css";

export const Chip: TChip = ({ label, checked, className }) => {
  const baseClass = "chip-secondary";
  const classes = [baseClass, checked && `${baseClass}--checked`, className].filter(Boolean).join(" ");
  return (
    <div className={classes}>
      <span className="chip-secondary__label">{label}</span>
    </div>
  );
};
