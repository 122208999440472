import { getWindow } from "../../utils/getWindow/getWindow";
import { paymentsAxios } from "./PaymentsAxiosInstance";

export class PaymentsService {
  static async createCheckoutSession(priceId: string, mode: "subscription" | "payment") {
    try {
      const { data } = await paymentsAxios.post<Record<"url", string>>("/api/stripe/create-checkout-session", {
        priceId,
        mode,
      });
      getWindow().location.replace(data.url);
    } catch (error) {
      console.error("createCheckoutSession", error);
      throw Promise.reject();
    }
  }

  static async createPortalSession() {
    try {
      const { data } = await paymentsAxios.post<Record<"url", string>>("/api/stripe/create-portal-session", {});
      getWindow().location.replace(data.url);
    } catch (error) {
      console.error("createCheckoutSession", error);
      throw Promise.reject();
    }
  }
}
