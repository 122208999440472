import { useEffect, useState } from "react";
import { Trait } from "grapesjs";
import { Check } from "../../../../../../assets/svg/Check.tsx";

export const Checkbox = ({ trait }: { trait: Trait }) => {
  const [value, setValue] = useState(trait.getValue());

  useEffect(() => {
    trait.setValue(value);
  }, [value]);

  return (
    <li key={trait.cid} className="trait-manager__trait">
      <label className="trait-manager__checkbox">
        <span className="trait-manager__checkbox-label">{trait.getLabel()}</span>
        <input type="checkbox" onChange={(e) => setValue(e.target.checked)} checked={value} />
        <span className="trait-manager__checkbox-marker">
          <Check />
        </span>
      </label>
    </li>
  );
};
