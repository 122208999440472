import { useEffect, useState } from "react";
import { getHighlighter } from "shiki";

import { Hint } from "../../../../../../../assets/svg/Hint.tsx";
import { Close } from "../../../../../../../assets/svg/Close.tsx";
import { Copy } from "../../../../../../../../assets/svg/copy.tsx";
import { Download } from "../../../../../../../../assets/svg/download.tsx";

import { Button } from "../../../../../../AlphaO/atoms/Button/Button.tsx";
import { LoadingDots } from "../../../../../../atoms/LoadingDots/LoadingDots.tsx";
import { TreeView } from "../../../../../../atoms/TreeView/TreeView.tsx";
import { Select } from "../../../../../../atoms/Select/Select.tsx";

import { modalMachineInstance } from "../../index.ts";
import { editorService } from "../../../editor/index.ts";
import { projectsService } from "../../../../../../../machines/projects/projects.instance.ts";

import { copyToClipboard } from "../../../../../../../utils/copyToClipboard/copyToClipboard.ts";
import { formatCode } from "../../../../../../../utils/formatCode/formatCode.ts";
import { saveAsZip } from "../../../../../../../utils/saveAsZip/saveAsZip.ts";

import "./ViewCode.css";
import { ComingSoon } from "../../../../../../atoms/CoomingSoon/ComingSoon.tsx";

type Data = {
  [key: string]: string | { [key: string]: string };
};

const Loader = () => {
  return (
    <p className="view-code-modal__description">
      Higlighting and Formatting
      <LoadingDots />
    </p>
  );
};

export const ViewCodeModal = () => {
  const [highlightedCode, setHighlightedCode] = useState("");
  const [selectedFile, setSelectedFile] = useState("");
  const [selectedContent, setSelectedContent] = useState("");
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [isHighlightingCode, setIsHighlightingCode] = useState(false);
  const [isDownloadingZip, setIsDownloadingZip] = useState(false);

  const handleNodeClick = (fileName: string, content: string) => {
    setSelectedFile(fileName);
    setSelectedContent(content);
  };

  const handleCopyClick = () => {
    copyToClipboard(formatCode(selectedContent || ""));
    setTooltipVisible(true);
    setTimeout(() => {
      setTooltipVisible(false);
    }, 2000);
  };

  const data: Data = {
    "index.html":
      editorService
        .getEditor()
        ?.getHtml()
        .replace(/<\/svg\s*>/g, "</svg>\n") || "",
    "styles.css": editorService.getEditor()?.getCss() || "",
  };

  const downloadProjectbyID = async () => {
    setIsDownloadingZip(true);
    try {
      const response = await projectsService.downloadActiveProject();
      saveAsZip(response?.data.data);
    } catch (error) {
      console.error("Error downloading the project:", error);
    } finally {
      setIsDownloadingZip(false);
    }
  };

  useEffect(() => {
    const firstRootFileKey = Object.keys(data).find((key) => typeof data[key] === "string"); // Find the first file at the root level and set it as the selected content
    if (firstRootFileKey) {
      setSelectedFile(firstRootFileKey);
      setSelectedContent(data[firstRootFileKey] as string);
    }
  }, []);

  useEffect(() => {
    async function fetchHighlighter() {
      setIsHighlightingCode(true);
      const highlighter = await getHighlighter({
        langs: ["html", "css", "js"],
        themes: ["dark-plus"],
      });
      const htmlCode = selectedContent || "";

      const codeLanguage = selectedFile.split(".")[1];

      const formattedHtml = formatCode(htmlCode, codeLanguage);

      const htmlHighlighted = highlighter.codeToHtml(formattedHtml, {
        lang: codeLanguage,
        theme: "dark-plus",
        structure: "inline",
      });

      setHighlightedCode(htmlHighlighted);
      setIsHighlightingCode(false);
    }

    if (selectedContent) {
      fetchHighlighter();
    }
  }, [selectedContent]);

  return (
    <div className="view-code-modal">
      <header className="view-code-modal__header">
        <div className="view-code-modal__header-text">
          <h2 className="view-code-modal__heading">Export code</h2>
          <p className="view-code-modal__description">Review and download your code</p>
        </div>
        <Button
          className="view-code-modal__close-btn"
          onClick={() => modalMachineInstance.send("CLOSE")}
          iconStart={<Close />}
          aria-label="close modal"
        />
      </header>
      <div className="view-code-modal__content">
        <nav className="view-code-modal__navigation">
          <div className="view-code-modal__framework-select">
            <ComingSoon>
              <div className="view-code-modal__framework-select-heading">
                <Hint /> <span>Framework</span>
              </div>
              <Select options={[{ label: "HTML" }]} onChange={(selected) => console.log({ selected })} />
            </ComingSoon>
          </div>
          <TreeView data={data} onNodeClick={handleNodeClick} />
        </nav>
        <article className="view-code-modal__code">
          {!isHighlightingCode ? <div dangerouslySetInnerHTML={{ __html: highlightedCode }} /> : <Loader />}
          {!isHighlightingCode && highlightedCode.length > 0 && (
            <Button className="view-code-modal__code-btn" iconStart={<Copy />} onClick={handleCopyClick}>
              Copy
            </Button>
          )}
          {highlightedCode.length > 0 && (
            <div className={`view-code-modal__tooltip${tooltipVisible ? " view-code-modal__tooltip--active" : ""}`}>
              Copied to clipboard!
            </div>
          )}
        </article>
      </div>
      <footer className="view-code-modal__footer">
        <span className="view-code-modal__description">Download all source files at once</span>
        <Button
          className="view-code-modal__btn"
          type="secondary"
          iconStart={<Download />}
          loading={isDownloadingZip}
          onClick={async () => await downloadProjectbyID()}
        >
          Download project
        </Button>
      </footer>
    </div>
  );
};
