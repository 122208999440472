import React from "react";
import { Button } from "../../../../../../AlphaO/atoms/Button/Button";
import { Edit } from "../../../../../../../assets/svg/Edit";
import { Hint } from "../../../../../../../assets/svg/Hint";
import { Close } from "../../../../../../../assets/svg/Close";
import { ArrowUpRight } from "../../../../../../../assets/svg/ArrowUpRight";
import { LoadingDots } from "../../../../../../atoms/LoadingDots/LoadingDots";
import { StateValueMap } from "xstate";
import { AlertTriangle } from "../../../../../../../../assets/svg/alert-triangle";
import { cleanUrl } from "../../../../utils/clean-url";
import { modalMachineInstance } from "../../index";
import { useAsyncAction } from "../../../../../../../utils/useAsyncAction/useAsyncAction";
import { PaymentsService } from "../../../../../../../services/Payments/Payments";
import { UserService } from "../../../../../../../services/UserService/UserService";

import "./Domains.css";

const userService = UserService.getInstance();

const salesLink =
  "mailto:sales@codejetai.co?subject=Inquiry%20About%20the%20Custom%20Team%20Plan&body=Hello%2C%0A%0AI%20would%20like%20to%20learn%20more%20about%20the%20Custom%20plan%20for%20teams%20that%20offers%20advanced%20features%20such%20as%20a%20custom%20design%20system%20and%20integrations.%20We%20are%20interested%20in%20code%20generation%20customization%20options%20and%20reverse%20proxy%20publishing%2C%20as%20well%20as%20embedding%20and%20branding%20possibilities.%20Could%20you%20provide%20more%20information%20about%20these%20features%20and%20the%20technical%20support%20available%20within%20this%20plan%3F%0A%0ABest%20regards%2C%0A%5BYour%20Name%5D";

// Types
interface DomainSwitchProps {
  isActive: boolean;
  onChange?: () => void;
  isDisabled: boolean;
}

interface DomainAttributesProps {
  name: string;
  status: string;
  isPublished: boolean;
  link?: string;
}

interface EditButtonProps {
  onClick?: () => void;
}

interface CloseButtonProps {
  onClick?: () => void;
}

interface CustomDomainProps {
  activeProject: any;
  isSwitchDisabled: boolean;
  handlePublishCustomDomain?: () => void;
  showContactSales: boolean;
  isActiveSubscription: boolean;
  canEditCustomDomain: boolean;
}

interface SubdomainProps {
  activeProject: any;
  handlePublishSubdomain: () => void;
  isDefaultEditPanelVisible: boolean;
  setIsDefaultEditPanelVisible: (value: boolean) => void;
  subdomain: string;
  handleChangeSubdomain: (e: React.ChangeEvent<HTMLInputElement>) => void;
  subdomainError: string | null;
  handleSaveSubdomain: () => void;
  disabledSave: boolean;
}

interface DomainsModalFooterProps {
  activeProject: any;
  editorState: string | StateValueMap;
  handlePublishChanges: () => void;
}

const DomainSwitch: React.FC<DomainSwitchProps> = ({ isActive, onChange, isDisabled }) => (
  <label
    className={`domain__switch${isActive ? " domain__switch--active" : ""}${
      isDisabled ? " domain__switch--disabled" : ""
    }`}
  >
    <input
      type="checkbox"
      className="domain__switch-input"
      checked={isActive}
      onChange={onChange}
      disabled={isDisabled}
    />
    <div className="domain__switch-marker"></div>
  </label>
);

const DomainAttributes: React.FC<DomainAttributesProps> = ({ name, status, isPublished, link }) => (
  <div className="domain__attributes">
    <h3 className="domain__name">
      {isPublished && link ? (
        <a href={link} target="_blank" rel="noopener noreferrer">
          {name} <ArrowUpRight />
        </a>
      ) : (
        name
      )}
    </h3>
    <p className="domain__status">
      {isPublished ? <span className="domain__status-published">Published</span> : status}
    </p>
  </div>
);

const EditButton: React.FC<EditButtonProps> = ({ onClick }) => (
  <Button type="ternary" className="domain__edit-button" aria-label="edit domain" onClick={onClick}>
    <Edit />
  </Button>
);

const CloseButton: React.FC<CloseButtonProps> = ({ onClick }) => (
  <Button type="ternary" className="domain__close-button" aria-label="close edit domain" onClick={onClick}>
    <Close />
  </Button>
);

const CustomDomain: React.FC<CustomDomainProps> = ({
  activeProject,
  isSwitchDisabled,
  handlePublishCustomDomain,
  showContactSales,
  isActiveSubscription,
  canEditCustomDomain,
}) => {
  const priceID = import.meta.env.VITE_SUBSCRIPTION_PRICE_ID;
  const customDomainAddress = activeProject?.customDomain?.address || "Custom domain";
  const isCustomDomainPublished = activeProject?.customDomain?.published;
  const { isLoading, handleAsyncAction } = useAsyncAction();
  const isActiveFlag = userService.isActiveSubscriptionFlag();

  return (
    <div className="domain">
      <div className="domain__domain">
        <DomainSwitch
          isActive={isCustomDomainPublished}
          onChange={handlePublishCustomDomain}
          isDisabled={isSwitchDisabled}
        />
        <DomainAttributes
          name={customDomainAddress}
          status="Not published"
          isPublished={isCustomDomainPublished}
          link={customDomainAddress}
        />
        {showContactSales && (
          <a className="domain__upgrade-button" href={salesLink}>
            Upgrade Your Plan
          </a>
        )}
        {!isActiveSubscription && (
          <Button
            className="domain__upgrade-button"
            aria-label="upgrade plan"
            loading={isLoading}
            onClick={() => {
              if (isActiveFlag) {
                return handleAsyncAction(async () => await PaymentsService.createPortalSession());
              }
              handleAsyncAction(async () => await PaymentsService.createCheckoutSession(priceID, "subscription"));
            }}
          >
            Upgrade Your Plan
          </Button>
        )}
        {canEditCustomDomain && (
          <EditButton onClick={() => modalMachineInstance.send("CHANGE_VIEW", { viewId: "customDomain" })} />
        )}
      </div>
    </div>
  );
};

const Subdomain: React.FC<SubdomainProps> = ({
  activeProject,
  handlePublishSubdomain,
  isDefaultEditPanelVisible,
  setIsDefaultEditPanelVisible,
  subdomain,
  handleChangeSubdomain,
  subdomainError,
  handleSaveSubdomain,
  disabledSave,
}) => {
  const subdomainAddress = activeProject?.subdomain?.address || "Subdomain";
  const isSubdomainPublished = activeProject?.subdomain?.published;
  const activeSubdomain = cleanUrl(activeProject?.subdomain?.address);

  return (
    <div className="domain">
      <div className="domain__subdomain">
        <DomainSwitch isActive={isSubdomainPublished} onChange={handlePublishSubdomain} isDisabled={false} />
        <DomainAttributes
          name={subdomainAddress}
          status="Not published"
          isPublished={isSubdomainPublished}
          link={subdomainAddress}
        />
        {isDefaultEditPanelVisible ? (
          <CloseButton onClick={() => setIsDefaultEditPanelVisible(false)} />
        ) : (
          <EditButton onClick={() => setIsDefaultEditPanelVisible(true)} />
        )}
      </div>
      {isDefaultEditPanelVisible && (
        <div className="domain__hidden-panel show">
          <div className="domain__edit-panel">
            <h3 className="domain__h3">Codejet subdomain</h3>
            <div className={`domain__form-wrapper${subdomainError ? " domain__form-wrapper--error" : ""}`}>
              <label>
                <input type="text" placeholder={activeSubdomain} value={subdomain} onChange={handleChangeSubdomain} />
                <span>.codejet.site</span>
                {subdomainError !== null && (
                  <span className="domain__error">
                    <AlertTriangle style={{ height: "20px", width: "20px" }} />
                    {` Issue detected - ${subdomainError}`}
                  </span>
                )}
              </label>
              <Button
                type="secondary"
                className="domain__update-domain"
                disabled={disabledSave}
                onClick={handleSaveSubdomain}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const DomainsModalFooter: React.FC<DomainsModalFooterProps> = ({
  activeProject,
  editorState,
  handlePublishChanges,
}) => (
  <footer className="domains-modal__footer">
    <a href="https://docs.codejet.ai/tools/launch" target="_blank" rel="noopener noreferrer">
      <Hint /> <span>Learn about publish</span>
    </a>
    {activeProject?.subdomain?.published || activeProject?.customDomain?.published ? (
      <>
        {activeProject?.isSynced && (
          <Button type="secondary" disabled>
            Up to date
          </Button>
        )}
        {!activeProject?.isSynced && editorState === "PUBLISH_UPDATES" && (
          <Button type="secondary">
            Publishing updates <LoadingDots />
          </Button>
        )}
        {!activeProject?.isSynced && editorState !== "PUBLISH_UPDATES" && (
          <Button type="secondary" onClick={handlePublishChanges}>
            Publish updates
          </Button>
        )}
      </>
    ) : null}
  </footer>
);

export { DomainSwitch, DomainAttributes, EditButton, CustomDomain, Subdomain, DomainsModalFooter };
