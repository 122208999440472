import { useEffect, useState } from "react";
import dayjs from "dayjs";

import { ImportModal } from "../../Dashboard/molecules/ImportModal/ImportModal.service";
import { UserService } from "../../../services/UserService/UserService";
import { projectsInstance } from "../../../machines/projects/projects.instance";

import { Seo } from "../../atoms/Seo";
import { InfoBar } from "../../Dashboard/atoms/InfoBar";
import { WeirdIconWrapper } from "../../atoms/WeirdIconWrapper";
import { TutorialCard } from "../../Dashboard/atoms/TutorialCard";
import { Navigation } from "../../Dashboard/organisms/Navigation";
import { RenderContent } from "../../Dashboard/organisms/ProjectsSection/RenderContent";

import { AppTourIcon } from "../../../../assets/svg/app-tour-icon";
import { VideoTutorialIcon } from "../../../../assets/svg/video-tutorial-icon";
import { ImportFromFigmaIcon } from "../../../../assets/svg/import-from-figma-icon";

import type { TMyProjects } from "./MyProjects.types";
import type { Project } from "../../../types/types";
import type { InfoBarType } from "../../Dashboard/atoms/InfoBar/InfoBar.types";

import "./MyProjects.css";

const importModal = ImportModal.getInstance();
const userService = UserService.getInstance();

const tutCards = [
  {
    icon: (
      <WeirdIconWrapper>
        <AppTourIcon />
      </WeirdIconWrapper>
    ),
    title: "Documentation",
    subtitle: "Learn how to use Codejet tools",
    onClick: "https://docs.codejet.ai",
  },
  {
    icon: (
      <WeirdIconWrapper>
        <VideoTutorialIcon />
      </WeirdIconWrapper>
    ),
    title: "Video tutorial",
    subtitle: "Video tutorials on how to use Codejet tools",
    onClick: "https://www.youtube.com/@codejetai",
  },
  {
    icon: (
      <WeirdIconWrapper>
        <ImportFromFigmaIcon />
      </WeirdIconWrapper>
    ),
    title: "Import from Figma",
    subtitle: "Import your designs directly from Figma",
    onClick: importModal.openModal,
  },
];

export const MyProjects: TMyProjects = () => {
  const [projects, setProjects] = useState<Project[]>([]);
  const [isSkeleton, setIsSkeleton] = useState(true);
  const [isNoContent, setIsNoContent] = useState(false);
  const [infoBarStatus, setInfoBarStatus] = useState<InfoBarType>("");
  const projectService = projectsInstance.getSnapshot().context.service;

  const canPurchase = () => {
    const currentTimestamp = dayjs().unix();
    const currentPeriodEnd = userService.data?.CURRENT_PERIOD_END || 0;
    const isActiveSubscription = currentTimestamp < currentPeriodEnd;
    const isCancelledSubscription = userService.isCanceledSubscription();
    const isUndefinedSubscription = userService.isUndefinedSubscription();
    const isActiveLifeTime = userService.isActiveLifeTime();

    return (isCancelledSubscription || isUndefinedSubscription || !isActiveLifeTime) && !isActiveSubscription;
  };

  useEffect(() => {
    projectService.subscribe((state) => setProjects(state));
    const projectState = projectService.state;
    if (projectState.length === 0) {
      projectsInstance.send("FETCH_PROJECTS");
      projectsInstance.onTransition((state) => {
        if (state.value === "AWAITING_PROJECT_SELECTION") {
          setIsSkeleton(false);
        }
      });
    } else {
      setIsSkeleton(false);
      setProjects(projectState);
    }
    return () => {
      projectService.unsubscribe((state) => setProjects(state));
    };
  }, []);

  useEffect(() => {
    if (isSkeleton === false) {
      if (projects.length === 0) {
        setIsNoContent(true);
      }
      if (canPurchase() && projects.length === 0) {
        setInfoBarStatus("");
      }
      if (canPurchase() && projects.length >= 1) {
        setInfoBarStatus("free-plan");
      }
      if (!canPurchase() && projects.filter((project) => project.customDomain?.published).length === 5) {
        setInfoBarStatus("professional-plan-domains");
      }
      if (!canPurchase() && projects.length === 100) {
        setInfoBarStatus("professional-plan-projects");
      }
    }
  }, [projects, isSkeleton]);

  const reversedProjects = [...projects].reverse();

  return (
    <>
      <Seo title="My projects - Codejet" />
      <Navigation />
      <div
        className={`my-projects__content${infoBarStatus.length > 0 ? " my-projects__content-threerows" : ""} container`}
      >
        <InfoBar type={infoBarStatus} />
        <div className="my-projects__cards">
          {tutCards.map((card) => (
            <TutorialCard key={`${card.title}`} {...card} />
          ))}
        </div>
        <section className="my-projects__projects">
          <div className="my-projects__projects-typography">
            <span className="my-projects__projects-title">My projects</span>
            <span className="my-projects__projects-subtitle">List of your recent projects</span>
          </div>
          <div className={`my-projects__projects-content${isNoContent ? " my-projects__projects-content--empty" : ""}`}>
            <RenderContent
              isPlanActive={canPurchase}
              isSkeleton={isSkeleton}
              isNoContent={isNoContent}
              projects={reversedProjects}
            />
          </div>
        </section>
      </div>
    </>
  );
};
