import type { ReactNode } from "react";
import { toast, type ToastOptions } from "react-toastify";
import { Typography, type TypographyProps } from "@mui/material";

type ToastWithLinkProps = {
  message?: string;
  linkMessage?: string;
  href?: string;
  sxMessage?: TypographyProps["sx"];
  sxLinkMessage?: TypographyProps["sx"];
  toastOptions: ToastOptions;
};

export const toastWithLink = ({
  message,
  linkMessage,
  href,
  sxMessage,
  sxLinkMessage,
  toastOptions,
}: ToastWithLinkProps) => {
  if (message && message.trim() !== "") {
    toast<ReactNode>(
      () => (
        <Typography sx={sxMessage}>
          {message}{" "}
          {linkMessage && (
            <Typography component="a" sx={{ color: "#FFFFFF", ...sxLinkMessage }} href={href}>
              {linkMessage}
            </Typography>
          )}
        </Typography>
      ),
      toastOptions
    );
  }
};
