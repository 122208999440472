import type { SVGProps } from "react";
export const FigmaLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
    <rect width="24" height="24" fill="url(#pattern0_11701_14070)" />
    <defs>
      <pattern id="pattern0_11701_14070" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use xlinkHref="#image0_11701_14070" transform="scale(0.0208333)" />
      </pattern>
      <image id="image0_11701_14070" width="48" height="48" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAPASURBVHgB3ZpBbxNHFMf/b+yGilRo297aSh167SX+Brai9lr31kot9qqH9hr10l4a05YbQvkACC8CQW4kZ0DxNzAfYZEIcGMVQKA4O483hiC47MzsJF7gJ0XO2u9t9j878+b9vSEEwH2d7JVmSIp+APOKvJVUxS999g1OyI+DAkR35HyX6cKlDIGQb+Djvu6yMWP5VfvmeAp4kxxKpXT+4sQ3QfkEPflRD+TidxBw8TXRkL/Df/428E1wCnjW19qUZgOLhHmD//pD+4Q6BczYrMMx14+BBLPZ2CewUoAdfTCGaALmLo+GzoGrFDAryxU0yZ7qu0IqBTBRswI8ioZXFXqXqRRAzDmaxG5wDioFlK3WFpqkLOMEfLqV221+gmbIaCPLXUHONVASpSKiwGIpZEc+6xPoFCB3IRcBa1gsaz6jb/GqQqe28oyU6klZzXG82GauF9KVenejh+z19VB2yQEDK1KljrKd3pZpY+d90HRthwTf6HOytYyEjN3k5A2ujud9nB1coNHhsd7hZGaMDID4CcIKs5zr8Dxkit3VdvBa8xZw5RfuPi4xhoGeXzcjiK9ucnff8FguXuONfCtCXrpg1f3itllXhtJ73/lXPq81cPVnmTIlavuBL2/xwBDvyEXrykD53MbZeHjiFHD9J5aTorYfkGmjZUsPymfwxjzPA/c+oLAOru8HZgbrHJ6fzEqO9wPz0Y/wAw8+x9fMPEQNZIl07aJ3xVUKOJBKgQhEQFT+zJRxfsBECnh0kqPy2ZB2xXzgfsBu7RGc3I9sPUhF+oElRPmBb+9iGxGYFuIEpBkVchcmqMmpp6ifT5w97LnvoHMNHDBS6Xtq+4GyRSkhLF/6o8IodTR+IN2kXJq32n5gPoqhfqI0az6jb/GqQmc2KSNGDzUX9e4qZYqpJ1UhrwyUz23c7vftDJ54l9FfN2ly5jqdli0ync/rwGllO8z7q+o00XxKTehVvn19ecxrS4o6IZ2oJcgPJNNR8jvOJcq2W75J194+tHdDXjIcEd4CPpn+190HjQmsA63AseI1hZan/w/EhIkf8GtxF4lTwMfTkbb9Od5RnAIUtZt4PuBNpQA7+qjZzy+KSgEfodX01+tOqps54P0W8D7g8APRzwec7XAsjjVgovzAEtrNCig6o9r9PIGyovN3jmPG/b0QyhQI9gOFVDCvfj4Wp4DnnVEuayGon7fxixh9i1cVetr5J5NA8QMuk0G5jbPxWBDBzweWp/8ORbc8H3j97zayTuiONHvbJ3CQ2XWDBfICT1pn6jEtbT0AAAAASUVORK5CYII=" />
    </defs>
  </svg>
);
