import { type CSSProperties } from "react";

type TActions = {
  className?: string;
  style?: CSSProperties;
};

export const Actions = ({ className, style }: TActions) => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M9.99935 11.3335C10.4596 11.3335 10.8327 10.9604 10.8327 10.5002C10.8327 10.0399 10.4596 9.66683 9.99935 9.66683C9.53911 9.66683 9.16602 10.0399 9.16602 10.5002C9.16602 10.9604 9.53911 11.3335 9.99935 11.3335Z"
      stroke="#71717A"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.99935 5.50016C10.4596 5.50016 10.8327 5.12707 10.8327 4.66683C10.8327 4.20659 10.4596 3.8335 9.99935 3.8335C9.53911 3.8335 9.16602 4.20659 9.16602 4.66683C9.16602 5.12707 9.53911 5.50016 9.99935 5.50016Z"
      stroke="#71717A"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.99935 17.1668C10.4596 17.1668 10.8327 16.7937 10.8327 16.3335C10.8327 15.8733 10.4596 15.5002 9.99935 15.5002C9.53911 15.5002 9.16602 15.8733 9.16602 16.3335C9.16602 16.7937 9.53911 17.1668 9.99935 17.1668Z"
      stroke="#71717A"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
