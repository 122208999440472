import { ActiveProject, ProjectHistory } from "./../../types/types";
import { projectsAxios } from "./ProjectsAxiosInstance.ts";
import { Project } from "../../types/types.ts";
import { Subscriber } from "../abstract/Subscriber/Subscriber.ts";
import { ISubscriber } from "../abstract/Subscriber/Subscriber.types.ts";
import { cleanUrl } from "../../components/pages/Alpha_O/utils/clean-url.ts";

export class Projects extends Subscriber<Project[]> {
  state = [] as Project[];
  //store scripts like resetCss to add to head when project is loaded
  scripts = [] as string[];
  async getProjectsBy(userFigmaId: string) {
    const { data } = await projectsAxios.get<[]>(`/api/projects/user/${userFigmaId}`, {});

    this.notifySubscribers();
    return data;
  }

  setActiveProject = (projectId: string) => {
    this.state = this.state.map((project) => ({
      ...project,
      active: project.projectId === projectId,
    }));
  };

  saveHistory = async (projectId: string, data: ProjectHistory) => {
    return projectsAxios.post<{ status: string }>(`/api/project-history/${this.transformProjectId(projectId)}`, data);
  };

  getHistory = async (projectId: string) => {
    const { data } = await projectsAxios.get<ProjectHistory>(
      `/api/project-history/${this.transformProjectId(projectId)}`,
      {}
    );
    return data;
  };
  getProjectsWithPublishedCustomDomain = () => {
    return this.state.filter((project) => project.customDomain.published);
  };

  getHtmlByProjectId = async (projectId: string) => {
    const { data } = await projectsAxios.get<string>(`/api/project-html/${this.transformProjectId(projectId)}`, {});

    return data;
  };

  getCssByProjectId = async (projectId: string) => {
    const { data } = await projectsAxios.get<string>(`/api/project-css/${this.transformProjectId(projectId)}`, {});

    return data;
  };

  getVariablesByProjectId = async (projectId: string) => {
    const { data } = await projectsAxios.get<string>(
      `/api/project-css/variables/${this.transformProjectId(projectId)}`,
      {}
    );

    return data;
  };

  saveProjectHtml = async (projectId: string, html: string) => {
    const { data } = await projectsAxios.post<string>(`/api/project-html/${this.transformProjectId(projectId)}`, {
      html,
    });

    return data;
  };

  saveProjectCss = async (projectId: string, css: string, file_name?: string) => {
    const { data } = await projectsAxios.post<string>(`/api/project-css/${this.transformProjectId(projectId)}`, {
      css,
      file_name,
    });

    return data;
  };

  saveProjectScript = async (projectId: string, script: string) => {
    const { data } = await projectsAxios.post<string>(`/api/project-script/${this.transformProjectId(projectId)}`, {
      script,
    });

    return data;
  };

  updateProjectData = async (
    projectId: string,
    updatedProjectData: {
      customDomain?: string;
      subdomain?: string;
      name?: string;
      description?: string;
      customHead?: string;
      customCode?: string;
    }
  ) => {
    const transformedProjectId = this.transformProjectId(projectId);
    const { data } = await projectsAxios.patch<Project>(`/api/projects/${transformedProjectId}`, updatedProjectData);

    this.updateProject(data);
  };

  publishProject = async (projectData: { projectId: string; customDomain?: string }) => {
    const { data } = await projectsAxios.patch<Project>(`/api/projects/publish`, projectData);

    this.updateProject(data);
  };

  unpublishProject = async (projectData: { projectId: string; customDomain?: string }) => {
    const { data } = await projectsAxios.patch<Project>(`/api/projects/unpublish`, projectData);

    this.updateProject(data);
  };

  updateProject = (project: Project) => {
    this.state = this.state.map((p) => {
      if (p.projectId === project?.projectId) {
        return {
          ...p,
          ...project,
        };
      }
      return p;
    });
    this.notifySubscribers();
  };

  deleteProject = async (projectId: string) => {
    let data;
    try {
      const { data: message } = await projectsAxios.delete<[]>(`/api/projects/${this.transformProjectId(projectId)}`);
      data = message;
    } catch (error) {
      console.error(error);
    }
    this.state = this.state.filter((project) => project.projectId !== projectId);
    this.notifySubscribers();
    return data;
  };

  checkCustomDomainHealth = async () => {
    const activeProject = this.getActiveProject();
    const formattedCustomDomain = cleanUrl(activeProject?.customDomain?.address || "");

    if (activeProject) {
      const response = await projectsAxios.get(`/api/domain/healthy-check/${formattedCustomDomain}`);

      return response;
    }

    return null;
  };

  downloadActiveProject = async () => {
    const activeProject = this.getActiveProject();

    if (!activeProject) {
      return null;
    }

    const projectId = this.transformProjectId(activeProject.projectId);
    const response = await projectsAxios.get(`/api/projects/${projectId}`);

    return response;
  };

  invalidateDomain = async (projectId: string) => {
    await projectsAxios.get(`/api/projects/invalidate/${this.transformProjectId(projectId)}`);
  };

  setSynced = async (projectId: string, state: boolean) => {
    const updatedProject = await projectsAxios.patch(`/api/projects/${this.transformProjectId(projectId)}`, {
      isSynced: state,
    });

    this.updateProject(updatedProject.data);
  };

  getActiveProject = () => this.state.find((project) => project.active);
  sendStateToSubscriber = (sb: ISubscriber<Project[]>) => sb([...this.state]);
  transformProjectId = (projectId: string) => projectId.replace("/", "+");

  checkSubdomainAvailability = async (value: string, signal: AbortSignal) => {
    const response = await projectsAxios.get(`/api/projects/check-subdomain/${value}`, {
      signal,
    });

    return response;
  };
}
