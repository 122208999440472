export const saveAsZip = (data: ArrayBuffer, fileName = "codejet.zip"): void => {
  const uint8Array = new Uint8Array(data);
  const blob = new Blob([uint8Array], { type: "application/zip" });
  const downloadLink = document.createElement("a");
  downloadLink.download = fileName;
  downloadLink.href = URL.createObjectURL(blob);
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};
