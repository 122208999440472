import { type CSSProperties } from "react";

type TDesignStyle = {
  className?: string;
  style?: CSSProperties;
};

export const DesignStyle = ({ className, style }: TDesignStyle) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <mask
      id="mask0_21_7728"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="24"
    >
      <rect width="24" height="24" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_21_7728)">
      <path
        d="M18.2079 19.9346C18.5733 20.3112 19.0238 20.4996 19.5597 20.4996C20.0957 20.4996 20.5547 20.3112 20.9367 19.9346C21.3187 19.5577 21.5097 19.0961 21.5097 18.5496C21.5097 18.1444 21.4123 17.7578 21.2174 17.3898C21.0226 17.022 20.7983 16.6688 20.5444 16.3303L19.5597 15.1533L18.6252 16.3303C18.361 16.6688 18.1341 17.022 17.9444 17.3898C17.7546 17.7578 17.6597 18.1444 17.6597 18.5496C17.6597 19.0961 17.8424 19.5577 18.2079 19.9346Z"
        fill="#71717A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.67334 17V8.29582C3.67334 6.7659 4.21191 5.46238 5.28905 4.38524C6.36618 3.3081 7.67228 2.76953 9.20734 2.76953C10.7421 2.76953 12.0481 3.3081 13.1252 4.38524C14.2026 5.46238 14.7413 6.7659 14.7413 8.29582V17H11.5789V13.835H6.83538V17H3.67334ZM6.83571 10.6762H11.5792V8.30427C11.5792 7.64537 11.3487 7.08539 10.8876 6.62433C10.4263 6.16327 9.86628 5.93274 9.20767 5.93274C8.54877 5.93274 7.98864 6.16327 7.5273 6.62433C7.06624 7.08539 6.83571 7.64537 6.83571 8.30427V10.6762Z"
        fill="#71717A"
      />
    </g>
  </svg>
);
