import "grapesjs/dist/css/grapes.min.css";
import { useEffect, useState } from "react";
import { editorInstance, editorService } from "./modules/editor/index.ts";

import { Seo } from "../../atoms/Seo";
import { ModalContainer } from "./modules/modal/ModalContainer.tsx";
import { TopBarNew } from "./TopBarNew.tsx";
import { EyeSolid } from "../../../assets/svg/EyeSolid.tsx";
import { selectorManagerInstance } from "./modules/selector-manager/selector-manager.service.ts";
import { UpdateNotification } from "../../AlphaO/atoms/UpdateNotification/UpdateNotification.tsx";
import { assetManagerInstance } from "./modules/asset-manager";
import { RightSideBar } from "./modules/right-sidebar/RightSideBar.tsx";
import { LoaderBackdrop } from "../../molecules/LoaderBackdrop";
import { LeftSidebar } from "../../AlphaO/molecules/LeftSidebar/LeftSidebar.tsx";
import { ColorPickerModal } from "../../AlphaO/atoms/ColorPicker/ColorPicker.tsx";

import "./style.css";

export const Alpha_O = () => {
  const [state, updateState] = useState({});
  const [isPreview, setIsPreview] = useState(false);
  const [_, updateSelectorManager] = useState(selectorManagerInstance.state);
  const isEditorLoading = editorService.getEditor() === null;

  useEffect(() => {
    editorInstance.start();
    editorService.subscribe((st) => updateState(st));
    selectorManagerInstance.subscribe((st) => updateSelectorManager(st));

    return () => {
      editorInstance.stop();
      editorService.clearEditor();
      editorService.unsubscribe(updateState);
      assetManagerInstance.send("CLOSE");
    };
  }, []);

  const togglePreview = () => {
    editorInstance.send("TOGGLE_PREVIEW");
    setIsPreview((prev) => !prev);
  };

  return (
    <>
      <Seo title="Visual Editor - Codejet" />
      <div className={`alpha-o${isPreview ? " preview" : ""}`}>
        {isEditorLoading && (
          <div className="alpha-o__veil">
            <LoaderBackdrop />
          </div>
        )}
        <ModalContainer />
        <TopBarNew togglePreview={togglePreview} />
        <div className={`main-section${isPreview ? " preview" : ""}`}>
          <LeftSidebar />
          <div className="main-section__editor">
            <div id={"gjs"}></div>
            <UpdateNotification />
          </div>
          <RightSideBar />
          <button className="switch-off-preview" onClick={togglePreview}>
            <EyeSolid />
          </button>
        </div>
        <ColorPickerModal />
      </div>
    </>
  );
};
