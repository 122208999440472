import React, { type SVGProps } from "react";

export const CatalogClose = (props: SVGProps<SVGSVGElement>) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8.56335 4.25684H4.25607C3.4664 4.25684 2.82749 4.90293 2.82749 5.69259L2.82031 14.3071C2.82031 15.0968 3.4664 15.7429 4.25607 15.7429H15.7421C16.5318 15.7429 17.1779 15.0968 17.1779 14.3071V7.12835C17.1779 6.33869 16.5318 5.69259 15.7421 5.69259H9.9991L8.56335 4.25684Z"
      fill="#71717A"
    />
  </svg>
);
