import { useState, type MouseEvent } from "react";

import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);

import useOutsideClick from "../../../../hooks/useOutsideClick/useOutsideClick";
import { HamburgerSlim } from "../../../../../assets/svg/hamburger-slim";
import { Lock } from "../../../../../assets/svg/lock";

import { Modal } from "./components/Modal";
import { IconButton } from "../../../atoms/IconButton";
import { ComingSoon } from "../../../atoms/CoomingSoon";

import type { TProjectCard } from "./ProjectCard.types";

import "./ProjectCard.css";

export const ProjectCard: TProjectCard = ({
  name,
  timestamp,
  thumbnail,
  onClick,
  onEdit,
  onDelete,
  isLocked = () => false,
  isLoading: isLoadingProp = false,
  isPublished = false,
  skeleton = false,
  style,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(isLoadingProp);

  const menuRef = useOutsideClick<HTMLDivElement>(() => {
    setIsMenuOpen(false);
  });
  const modalRef = useOutsideClick<HTMLDivElement>(() => setIsModalOpen(false));

  const editedDate = dayjs(timestamp).from(dayjs());

  const handleOnClick = (e: MouseEvent<HTMLDivElement>) => {
    if (!isLocked()) {
      e.stopPropagation();
      onClick && onClick();
    }
  };

  const handleOnClickKey = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (!isLocked && (event.key === "Enter" || event.key === " ")) {
      event.preventDefault();
      onClick && onClick();
    }
  };

  const handleOpenModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setIsModalOpen(true);
  };

  const handleOpenMenu = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (!isLocked()) {
      setIsMenuOpen((prev) => !prev);
    }
  };

  const handleOpenMenuKey = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if ((!isLocked() && event.key === "Enter") || event.key === " ") {
      event.preventDefault();
      setIsMenuOpen((prev) => !prev);
    }
  };

  const handleOnDelete = async () => {
    if (onDelete !== undefined) {
      setIsLoading(true);
      await onDelete();
      setIsLoading(false);
    }
  };

  const handleOnEdit = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onEdit && onEdit();
  };

  const statusLabel = () => {
    if (isLocked()) {
      return "Locked - Upgrade Plan";
    }
    if (isPublished) {
      return "Published";
    }
    return "Unpublished";
  };

  return (
    <>
      <div
        className={
          "project-card" + (isMenuOpen ? " project-card--menu-open" : "") + (isLocked() ? " project-card--locked" : "")
        }
        tabIndex={0}
        role="button"
        onClick={handleOnClick}
        onKeyDown={handleOnClickKey}
        style={style}
      >
        {skeleton ? null : (
          <div className={`project-card__status${!isLocked() && isPublished ? " project-card__status--active" : ""}`}>
            {isLocked() ? <Lock /> : <div className="project-card__status-indicator"></div>}
            <span className="project-card__status-text">{statusLabel()}</span>
          </div>
        )}
        {skeleton ? (
          <div className="project-card__thumbnail skeleton-thumbnail skeleton" />
        ) : (
          <div className="project-card__thumbnail">
            <img className="project-card__thumbnail-img" src={thumbnail} loading="lazy" alt="project thumbnail" />
          </div>
        )}
        <div className="project-card__info">
          {skeleton ? (
            <div className="project-card__typography">
              <div className="skeleton skeleton-text" />
              <div className="skeleton skeleton-text" />
            </div>
          ) : (
            <div className="project-card__typography">
              <div className="project-card__title">{name}</div>
              <div className="project-card__description">{`Edited ${editedDate}`}</div>
            </div>
          )}
          <div
            className={"project-card__actions" + (isLoading || isMenuOpen ? " project-card__actions--visible" : "")}
            role="menu"
            tabIndex={0}
            ref={isModalOpen ? null : menuRef}
            onClick={handleOpenMenu}
            onKeyDown={handleOpenMenuKey}
          >
            <div className="project-card__dropdown-wrapper">
              <IconButton
                className={"project-card__btn" + (isMenuOpen ? " project-card__btn--menu-open" : "")}
                ariaLabel="project context menu"
              >
                <HamburgerSlim className={isLoading ? "loading-dots-animation" : ""} />
              </IconButton>
              <div className={"project-card__dropdown" + (isMenuOpen ? " project-card__dropdown--visible" : "")}>
                {/* <ComingSoon> */}
                <button className="project-card__dropdown-item" type="button" onClick={handleOnEdit}>
                  Settings
                </button>
                {/* </ComingSoon> */}
                <button
                  className={
                    "project-card__dropdown-item" + (isModalOpen ? " project-card__dropdown-item--hovered" : "")
                  }
                  type="button"
                  onClick={handleOpenModal}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal reference={modalRef} isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} onClick={handleOnDelete} />
    </>
  );
};
