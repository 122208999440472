import { Subscriber } from "../../../../services/abstract/Subscriber/Subscriber";

type IColorPickerService = {
  isOpen: boolean;
  color: string;
  position: { x: number; y: number };
  onChange: null | ((color: string) => void);
};

export class ColorPickerService extends Subscriber<IColorPickerService> {
  state: IColorPickerService = {
    isOpen: false,
    color: "#000",
    position: { x: 0, y: 0 },
    onChange: null,
  };

  openPicker = () => {
    this.state.isOpen = true;
    this.notifySubscribers();
  };

  closePicker = () => {
    this.state.isOpen = false;
    this.state.onChange = null;
    this.notifySubscribers();
  };

  setColor = (color: string) => {
    this.state.color = color;
    this.notifySubscribers();
  };

  setPosition = (position: IColorPickerService["position"]) => {
    this.state.position = position;
    this.notifySubscribers();
  };

  setCallback = (onChange: (color: string) => void) => {
    this.state.onChange = onChange;
  };

  setupColorPicker = (
    localValue: string,
    onChange: (color: string) => void,
    position: IColorPickerService["position"]
  ) => {
    this.state.position = position;
    this.state.color = localValue;
    this.state.onChange = onChange;
    this.state.isOpen = true;
    this.notifySubscribers();
  };
}

export const colorPickerServiceInstance = new ColorPickerService();
